import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { StoreContext } from '../../store'
import Error from '../../components/Error'
import Loading from '../../components/loading/Loading'

interface Props {
  email: string
}

export default observer(({ email }: Props) => {
  const { authStore } = React.useContext(StoreContext)

  const [code, setCode] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setLoading] = useState(false)

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setError('')

    setLoading(true)
    try {
      await authStore.confirmSignUp(email, code)
    } catch (err: any) {
      setError(err.message)
    }
    setLoading(false)
  }

  return (
    <div
      className={ `
        mt-16 max-w-md mx-auto
        flex flex-col
        p-16 border
      ` }>
      <p className="mb-4">
        We sent you a code to your email address: { email }
      </p>
      <form
        onSubmit={ onSubmit }>
        <label className="block mb-4">
          <span className="hidden">Code:</span>
          <input
            type="text"
            value={ code }
            className="border block w-full p-1 pl-2"
            placeholder="Code"
            onChange={ (e) => setCode(e.target.value) } />
        </label>

        { error
          && (
          <div className="mb-4">
            <Error msg={ error } />
          </div>
          ) }

        { isLoading
          && (
          <div className="mb-4">
            <Loading />
          </div>
          ) }

        { !isLoading
          && (
          <button
            type="submit"
            className={ `
            w-full p-1 mb-8
            bg-emerald-600 text-white
            font-medium
          ` }>
            Submit
          </button>
          ) }
      </form>

      <button
        className="underline text-center text-gray-700"
        onClick={ (e) => {
          e.preventDefault()
          setError('')
          authStore.resendSignUp(email)
        } }>
        Resend Code
      </button>
    </div>
  )
})
