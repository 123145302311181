import React from 'react'

import './TopHeader.scss'
import { StoreContext } from '../../store'
import yokozunaLogo from '../../assets/home/yokozuna-logo.png'
import { Path } from '../../interfaces/view'

export default function TopHeader() {
  const { viewStore } = React.useContext(StoreContext)

  const selected = (path: Path) => {
    if (path === viewStore.currentPath) {
      return 'selected'
    }

    return ''
  }

  return (
    <div className="header">
      <a href="/">
        <img
          className="yokozuna-logo"
          src={ yokozunaLogo }
          alt="Yokozuna data logo" />
      </a>

      { /* <!-- Navbar --> */ }
      <div className="navbar">
        <a
          className={ selected(Path.PRODUCT) }
          href={ Path.PRODUCT }>
          Product
        </a>
        <a
          className={ selected(Path.TEAM) }
          href={ Path.TEAM }>
          Team
        </a>
        <a
          className={ selected(Path.CONTACT) }
          href={ Path.CONTACT }>
          Contact
        </a>
        <a
          className={ selected(Path.SIGN_IN) }
          href={ Path.SIGN_IN }>
          Sign In
        </a>
      </div>
    </div>
  )
}
