import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { StoreContext } from '../../store'
import Error from '../../components/Error'
import Loading from '../../components/loading/Loading'
import Terms from '../../pages/terms/Terms'

export default observer(() => {
  const { authStore } = React.useContext(StoreContext)

  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [email, setEmail] = useState('')
  const [pwd, setPwd] = useState('')
  const [confirmPwd, setConfirmPwd] = useState('')
  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const [showTerms, setShowTerms] = useState(false)

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setError('')

    if (pwd !== confirmPwd) {
      return setError("Passwords don't match")
    }

    if (!acceptedTerms) {
      return setError(`
        You must accept the Terms & Conditions before
        signing up
      `)
    }

    setLoading(true)
    try {
      await authStore.signUp(email, pwd)
    } catch (err: any) {
      setError(err.message)
    }
    setLoading(false)
  }

  if (showTerms) {
    return (
      <div
        className={ `
          absolute top-0 left-0 right-0
          max-w-xl mx-auto
        ` }>
        <Terms onCloseClick={ () => {
          setShowTerms(false)
        } } />
      </div>
    )
  }

  return (
    <form
      className="flex flex-col p-8 sm:p-16 border-l border-r border-b"
      onSubmit={ onSubmit }>
      <label className="block mb-4">
        <span className="hidden"> Email: </span>
        <input
          type="text"
          value={ email }
          className="border block w-full p-1 pl-2"
          placeholder="Email"
          onChange={ (e) => setEmail(e.target.value) } />
      </label>

      <label className="block mb-4">
        <span className="hidden"> Password: </span>
        <input
          type="password"
          value={ pwd }
          className="border block w-full p-1 pl-2"
          placeholder="Password"
          onChange={ (e) => setPwd(e.target.value) } />
      </label>

      <label className="block mb-4">
        <span className="hidden">
          Confirm Password:
        </span>
        <input
          type="password"
          value={ confirmPwd }
          className="border block w-full p-1 pl-2"
          placeholder="Confirm password"
          onChange={ (e) => setConfirmPwd(e.target.value) } />
      </label>

      <label className="block mb-4">
        <input
          type="checkbox"
          checked={ acceptedTerms }
          className="mr-2"
          onChange={ () => setAcceptedTerms(!acceptedTerms) } />
        <span>
          I agree with the&nbsp;
          <button
            className="underline"
            onClick={ () => setShowTerms(true) }>
            Terms & Conditions
          </button>
        </span>
      </label>

      { error
        && (
        <div className="mb-4">
          <Error msg={ error } />
        </div>
        ) }

      { isLoading
        && (
        <div className="mb-4">
          <Loading />
        </div>
        ) }

      { !isLoading
        && (
        <button
          type="submit"
          className={ `
            p-1 mb-8
            bg-emerald-600 text-white
            font-medium
          ` }>
          Create Account
        </button>
        ) }
    </form>
  )
})
