/* eslint-disable no-unused-vars */

export interface ConfirmResetPassword {
  name: 'ConfirmResetPassword'
  path: Path.CONFIRM_RESET_PASSWORD
  state: { email: string }
}

export interface ConfirmSignUp {
  name: 'ConfirmSignUp'
  path: Path.CONFIRM_SIGN_UP
  state: { email: string }
}

export interface Contact {
  name: 'Contact'
  path: Path.CONTACT
}

export interface Dashboard {
  name: 'Dashboard'
  path: Path.DASHBOARD
}

export interface Hiring {
  name: 'Hiring'
  path: Path.HIRING
}

interface Home {
  name: 'Home'
  path: Path.HOME
}

export interface Product {
  name: 'Product'
  path: Path.PRODUCT
}

export interface ProjectData {
  name: 'ProjectData'
  path: Path.PROJECT_DATA
}

interface ResetPassword {
  name: 'ResetPassword'
  path: Path.RESET_PASSWORD
}

interface SignIn {
  name: 'SignIn'
  path: Path.SIGN_IN
}

interface SignUp {
  name: 'SignUp'
  path: Path.SIGN_UP
}

export interface Team {
  name: 'Team'
  path: Path.TEAM
}

export enum Path {
  CONFIRM_RESET_PASSWORD = '/GameDataScience/confirmResetPassword',
  CONFIRM_SIGN_UP = '/GameDataScience/confirmSignUp',
  CONTACT = '/contact',
  DASHBOARD = '/GameDataScience',
  HIRING = '/hiring',
  HOME = '/',
  PRODUCT = '/product',
  PROJECT_DATA = '/projectData',
  SIGN_IN = '/GameDataScience/signin',
  SIGN_UP = '/GameDataScience/signup',
  TEAM = '/team',
  RESET_PASSWORD = '/GameDataScience/resetPassword',
}

type View
  = ConfirmResetPassword
  | ConfirmSignUp
  | Contact
  | Dashboard
  | Hiring
  | Home
  | Product
  | ProjectData
  | ResetPassword
  | SignIn
  | SignUp
  | Team

export default View
