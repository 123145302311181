import { useContext } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChartBar,
  faDatabase,
  faSlidersH,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import logoReversed from '../../assets/images/Logo - reversed.svg'

import { Path } from '../../interfaces/view'
import { StoreContext } from '../../store'

interface HeaderProps {
  signOut: () => void
}

export default function Sidebar({ signOut }: HeaderProps) {
  const { viewStore } = useContext(StoreContext)

  return (
    <div className={ `
      h-screen py-2 z-40 top-0
      bg-gray-900 text-white
    ` }>
      <div className="w-full text-right pr-4">
        <FontAwesomeIcon
          icon={ faTimes }
          color="white"
          className="cursor-pointer text-right"
          onClick={ () => viewStore.hideSidebar() } />
      </div>

      <img
        className="w-36 m-auto"
        src={ logoReversed }
        alt="logo" />

      <div className="px-3 mt-4 flex flex-col items-start ml-5">
        <p className="mt-4">
          <a href={ Path.DASHBOARD }>
            <FontAwesomeIcon
              icon={ faChartBar }
              className="mr-2" />
            Dashboard
          </a>
        </p>
        <p className="mt-4">
          <a href={ Path.PROJECT_DATA }>
            <FontAwesomeIcon
              icon={ faDatabase }
              className="mr-2" />
            Project Data
          </a>
        </p>
        <p className="mt-4">
          <FontAwesomeIcon
            icon={ faSlidersH }
            className="mr-2" />
          Settings
        </p>
        {
          localStorage.getItem('role') === 'admin' && (
          <p className="mt-2">
            <a href="/users" className="cursor-pointer">
              <FontAwesomeIcon icon={ faSlidersH } className="mr-2" />
              Manage Users
            </a>
          </p>
          )
        }
      </div>

      <div className={ `
        absolute bottom-0 p-2 border-t-2 w-full
        text-center border-gray-800
      ` }>
        <button
          className="bg-gray-800 p-1 w-full"
          onClick={ signOut }>
          Logout
        </button>
      </div>
    </div>
  )
}
