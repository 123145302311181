import './product.scss'
import HowitworksEN from '../../assets/product/How-it-works-EN.png'
import iconlifespanprediction from '../../assets/product/icon-lifespan-prediction.png'
import iconmonetization from '../../assets/product/icon-monetization.png'
import iconplayerfocused from '../../assets/product/icon-player-focused.png'
import iconretention from '../../assets/product/icon-retention.png'
import icontakeaction from '../../assets/product/icon-take-action.png'
import iconwhatif from '../../assets/product/icon-what-if.png'
import itemrecommendericon from '../../assets/product/item-recommender-icon.png'
import PicLifetimeValue from '../../assets/product/PicLifetimeValue.png'
import PicLoginprobability from '../../assets/product/PicLoginprobability.jpg'
import PicPerformanceUserRetention from '../../assets/product/PicPerformanceUserRetention.png'
import PicTakeAction from '../../assets/product/PicTakeAction.png'
import Footer from '../../components/footer/Footer'
import TopHeader from '../../components/top-header/TopHeader'

export default function Product() {
  return (
    <div className="product">
      <TopHeader />

      { /* // <!-- How does it work --> */ }
      <div className="how-section section">
        <div className="container">
          <h3 className="title product-title">
            How does it work?
          </h3>
          <img
            src={ HowitworksEN }
            alt="" />
        </div>
      </div>

      { /* // <!-- Main benefits --> */ }
      <div className="benefits-section section">
        <h3 className="benefits-title">
          Main benefits
        </h3>
        <div className="body">
          <div className="benefit">
            <img
              src={ iconmonetization }
              alt="" />
            <p>
              Increase Game Monetization
            </p>
          </div>
          <div className="benefit">
            <img
              src={ iconretention }
              alt="" />
            <p>
              Optimize Playtime and VIP Retention
            </p>
          </div>
          <div className="benefit">
            <img
              src={ iconplayerfocused }
              alt="" />
            <p>
              Enhance Player-focused Data-driven Development
            </p>
          </div>
        </div>
      </div>

      { /* <!-- Key features --> */ }
      <div id="key-features" className="section">
        <h3 className="title-product">
          Some of our key features
        </h3>
        <div className="body">
          <div className="row">
            <div className="content">
              <img
                src={ PicLoginprobability }
                alt="" />
              <h4 className="title title-row">
                <img
                  src={ iconlifespanprediction }
                  alt=""
                  className="icon" />
                <span> Log-in probability </span>
              </h4>
              <p>
                What is the probability that a certain user will log in
                at least once in the next time window of your choice?
                YOKOZUNA data forecasts potential early "churners"
                from just 15 days of data.
              </p>
            </div>

            { /* <!-- Lifetime value --> */ }
            <div className="content">
              <img
                src={ PicLifetimeValue }
                alt="" />
              <h4 className="title title-row">
                <img
                  src={ iconwhatif }
                  alt=""
                  className="icon" />
                <span>Lifetime value</span>
              </h4>
              <p>
                Calculate the lifetime value of a player based on their
                existing history, which allows you to identify
                big spenders and try to enhance their purchase
                frequency and log-in probability.
              </p>
            </div>
          </div>

          <div className="row">
            { /* <!-- Performance and user retention --> */ }
            <div className="content">
              <img
                src={ PicPerformanceUserRetention }
                alt="" />
              <h4 className="title performance">
                <img
                  src={ itemrecommendericon }
                  alt="" />
                <span>Performance and user retention</span>
              </h4>
              <p>
                YOKOZUNA data provides analytics that detail
                player performance and user retention rates.
              </p>
            </div>

            { /* <!-- Take action --> */ }
            <div className="content">
              <img
                src={ PicTakeAction }
                alt="" />
              <h4 className="title title-row">
                <img
                  src={ icontakeaction }
                  alt=""
                  className="icon" />
                <span>Take Action</span>
              </h4>
              <p>
                Automatically push-notify players who are expected
                to leave the game, trying to re-engage them with
                the right message and personalized promotions.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}
