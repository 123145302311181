import { GraphFileName } from '../../interfaces'

// returns the headers that are required
// but not present in the file
const getMissingHeaders = (
  content: string,
  requiredHeaders: string[],
): string[] => {
  const [headersRow, ..._rows] = content.split('\n')
  const headers = headersRow.split(',')
  const missingHeaders: string[] = []

  requiredHeaders.forEach((reqHeader) => {
    if (headers.indexOf(reqHeader) === -1) {
      missingHeaders.push(reqHeader)
    }
  })

  return missingHeaders
}

const validationErrorMsg = (
  missingHeaders: string[],
) => {
  if (missingHeaders.length === 0) {
    return ''
  }

  if (missingHeaders.length === 1) {
    return `Invalid file, missing header ${missingHeaders[0]}.`
  }

  return `Invalid file, missing headers ${missingHeaders.join(' and ')}.`
}

export const validateDAU = (
  content: string,
): string => {
  return validationErrorMsg(
    getMissingHeaders(content, ['day', 'DAU']),
  )
}

export const validateMAU = (
  content: string,
): string => {
  return validationErrorMsg(
    getMissingHeaders(content, ['day', 'MAU']),
  )
}

export const validatePurchases = (
  content: string,
): string => {
  return validationErrorMsg(
    getMissingHeaders(content, ['day', 'sales']),
  )
}

export const validateSurvival = (
  content: string,
): string => {
  return validationErrorMsg(
    getMissingHeaders(content, ['type', 'value']),
  )
}

export const validateLTV = (
  content: string,
): string => {
  return validationErrorMsg(
    getMissingHeaders(content, ['type', 'nbDays', 'value']),
  )
}

export const validateRetention = (
  content: string,
): string => {
  return validationErrorMsg(
    getMissingHeaders(content, [
      'date',
      'users',
      'day_1',
      'day_2',
      'day_3',
      'day_4',
      'day_5',
      'day_6',
      'day_7',
      'day_14',
      'day_28',
      'day_30',
    ]),
  )
}

export const validators = {
  [GraphFileName.ANOMALIES]: () => '',
  [GraphFileName.DAU]: validateDAU,
  [GraphFileName.LTV]: validateLTV,
  [GraphFileName.MAU]: validateMAU,
  [GraphFileName.PURCHASES]: validatePurchases,
  [GraphFileName.RETENTION]: validateRetention,
  [GraphFileName.SURVIVAL_10_DAYS]: validateSurvival,
}
