import React from 'react'
import { observer } from 'mobx-react-lite'
import { StoreContext } from '../../store'

import Home from '../../pages/home/home'
import Product from '../../pages/product/product'
import Contact from '../../pages/contact/contact'
import Team from '../../pages/team/team'
import Hiring from '../../pages/hiring/hiring'
import { Path } from '../../interfaces/view'

export default observer(() => {
  const { viewStore } = React.useContext(StoreContext)

  switch (viewStore.currentPath) {
    case Path.CONTACT:
      return <Contact />
    case Path.HIRING:
      return <Hiring />
    case Path.HOME:
      return <Home />
    case Path.PRODUCT:
      return <Product />
    case Path.TEAM:
      return <Team />
    default:
      return <></>
  }
})
