import {
  VegaLite,
  VisualizationSpec,
} from 'react-vega'

import { observer } from 'mobx-react-lite'
import { GraphFileName } from '../../interfaces'
import Loading from '../../components/loading/Loading'
import Upload from '../../components/upload/Upload'
import GraphCardWithDates from './GraphCardWithDates'
import RetentionCard from './RetentionCard'

interface AbnormalPlayersCardProps {
  data: string[]
  onUploadDone: () => void
  setError: (error: string) => void
  url: string
}

interface VegaProps {
  data: string | null
  spec: VisualizationSpec
}

interface GraphCardProps {
  data: string | null
  fileName: string
  onUploadDone: () => void
  setError: (error: string) => void
  spec: VisualizationSpec
  title: string
  validateFileContent: (content: string) => string
}

export const Vega = observer(({ spec, data }: VegaProps) => {
  if (data === null) {
    return (
      <p className="text-center text-black">
        No data available
      </p>
    )
  }

  if (data.length === 0) {
    return <Loading />
  }

  return (
    <VegaLite
      className="w-full"
      spec={ spec } />
  )
})

export const GraphCard = observer(({
  data,
  fileName,
  onUploadDone,
  setError,
  spec,
  title,
  validateFileContent,
}: GraphCardProps) => {
  const cardHeader = `
    flex justify-between items-center
    p-4 text-white bg-gray-800
    uppercase text-sm leading-5 font-semibold
  `

  return (
    <div className={ `
      flex-auto w-full mb-8
      border-t-4 border-emerald-400
      bg-white
    ` }>
      <div className={ cardHeader }>
        <h2>{ title }</h2>
        <Upload
          fileName={ fileName }
          onUploadDone={ () => onUploadDone() }
          setError={ setError }
          validateFileContent={ validateFileContent } />
      </div>
      <div className="p-2 xl:p-6">
        <Vega
          data={ data }
          spec={ spec } />
      </div>
    </div>
  )
})

export const AbnormalPlayersCard = observer(({
  data,
  onUploadDone,
  setError,
  url,
}: AbnormalPlayersCardProps) => {
  const nbPlayers = data.length

  const title = nbPlayers > 0
    ? (
      <span> List of players with an abnormal behavior
        ({ nbPlayers } players,&nbsp;
        <span>
          <a className="underline" href={ url }>
            Download
          </a>
        </span>
        )
      </span>
    )
    : (<span> List of players with an abnormal behavior </span>)

  return (
    <div className={ `
      flex-auto w-full mb-8
      bg-gray-800 border-t-4 border-emerald-400
    ` }>
      <div className={ `
        flex justify-between items-center
        p-4 text-white
        uppercase text-sm leading-5 font-semibold
        border-b-2 border-b-gray-700
      ` }>
        <h2> { title } </h2>
        <Upload
          fileName={ GraphFileName.ANOMALIES }
          setError={ setError }
          onUploadDone={ () => onUploadDone() }
          validateFileContent={ () => '' } />
      </div>
      <div className="max-h-72 overflow-auto">
        { nbPlayers === 0
          && (
          <p className="text-center">
            No data available
          </p>
          ) }
        <table className="w-full">
          <tbody>
            { data.map((id) => {
              return (
                <tr key={ id }>
                  <td className="border-b-2 border-gray-700 p-2">
                    { id }
                  </td>
                </tr>
              )
            }) }
          </tbody>
        </table>
      </div>
    </div>
  )
})

export { GraphCardWithDates, RetentionCard }
