import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import DatePicker from 'react-datepicker'
import { GraphFileName, RetentionData } from '../../interfaces'
import Upload from '../../components/upload/Upload'
import { csvToObjects, filterByMonth } from './utils'

import 'react-datepicker/dist/react-datepicker.css'
import './DatePicker.scss'
import Loading from '../loading/Loading'

interface RetentionCardProps {
  data: string | null
  onUploadDone: () => void
  setError: (error: string) => void
}

export default observer(({
  data,
  onUploadDone,
  setError,
}: RetentionCardProps) => {
  // retentionMaxValue is used
  // to provide a reference for the color bg
  // increase it if you want to decrease opacity
  const retentionMaxValue = 60
  const retentionColorBg = (val: number) => {
    return `rgba(5, 150, 105, ${1 - val / retentionMaxValue})`
  }

  const [startDate, setStartDate] = useState(new Date())
  const [retentionData, setRetentionData] = useState<RetentionData[] | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  React.useEffect(() => {
    if (data && data !== '') {
      const rData: RetentionData[] = csvToObjects(data) as any
      const d = new Date(rData[0].date)
      const filteredData = filterByMonth(d, data)
      setStartDate(d)
      setRetentionData(csvToObjects(filteredData))
      setIsLoading(false)
    }
  }, [data])

  const table = () => {
    if (retentionData && retentionData.length === 0) {
      return (
        <div className="text-center">
          <p>No data available for this time period</p>
        </div>
      )
    }

    return (
      <table>
        <thead>
          <tr>
            <th className="pb-4">
              Cohort date
            </th>
            <th className="pb-4">
              Users
            </th>
            <th className="pb-4">
              Day 1
            </th>
            <th className="pb-4">
              Day 2
            </th>
            <th className="pb-4">
              Day 3
            </th>
            <th className="pb-4">
              Day 4
            </th>
            <th className="pb-4">
              Day 5
            </th>
            <th className="pb-4">
              Day 6
            </th>
            <th className="pb-4">
              Day 7
            </th>
            <th className="pb-4">
              Day 14
            </th>
            <th className="pb-4">
              Day 28
            </th>
            <th className="pb-4">
              Day 30
            </th>
          </tr>
        </thead>
        <tbody>
          { retentionData && retentionData.map(tableRow) }
        </tbody>
      </table>
    )
  }

  const tableRow = (row: RetentionData) => {
    return (
      <tr key={ row.date }>
        <td className="text-center px-6 whitespace-nowrap">
          { row.date }
        </td>
        <td className="text-center px-6">
          { row.users }
        </td>
        <td
          className="text-center px-6"
          style={ { backgroundColor: retentionColorBg(row['Day 1']) } }>
          { row['Day 1'] }%
        </td>
        <td
          className="text-center px-6"
          style={ { backgroundColor: retentionColorBg(row['Day 2']) } }>
          { row['Day 2'] }%
        </td>
        <td
          className="text-center px-6"
          style={ { backgroundColor: retentionColorBg(row['Day 3']) } }>
          { row['Day 3'] }%
        </td>
        <td
          className="text-center px-6"
          style={ { backgroundColor: retentionColorBg(row['Day 4']) } }>
          { row['Day 4'] }%
        </td>
        <td
          className="text-center px-6"
          style={ { backgroundColor: retentionColorBg(row['Day 5']) } }>
          { row['Day 5'] }%
        </td>
        <td
          className="text-center px-6"
          style={ { backgroundColor: retentionColorBg(row['Day 6']) } }>
          { row['Day 6'] }%
        </td>
        <td
          className="text-center px-6"
          style={ { backgroundColor: retentionColorBg(row['Day 7']) } }>
          { row['Day 7'] }%
        </td>
        <td
          className="text-center px-6"
          style={ { backgroundColor: retentionColorBg(row['Day 14']) } }>
          { row['Day 14'] }%
        </td>
        <td
          className="text-center px-6"
          style={ { backgroundColor: retentionColorBg(row['Day 28']) } }>
          { row['Day 28'] }%
        </td>
        <td
          className="text-center px-6"
          style={ { backgroundColor: retentionColorBg(row['Day 30']) } }>
          { row['Day 30'] }%
        </td>
      </tr>
    )
  }

  return (
    <div className={ `
      flex-auto w-full mb-8
      bg-gray-800 border-t-4 border-emerald-400
    ` }>
      <div className={ `
        flex justify-between items-center
        p-4 text-white
        uppercase text-sm leading-5 font-semibold
        border-b-2 border-b-gray-700
      ` }>
        <h2> Retention </h2>
        <DatePicker
          className="mr-4 bg-gray-700 border-0"
          dateFormat="MMMM yyyy"
          selected={ startDate }
          onChange={ (date) => {
            if (date && data) {
              const filteredData = filterByMonth(date, data)
              setRetentionData(csvToObjects(filteredData))
              setStartDate(date)
            }
          } }
          showMonthYearPicker />
        <Upload
          fileName={ GraphFileName.RETENTION }
          setError={ setError }
          onUploadDone={ () => onUploadDone() }
          validateFileContent={ () => '' } />
      </div>
      { isLoading
        ? <Loading className="my-4" />
        : (
          <div className="py-4 overflow-auto">
            { table() }
          </div>
        ) }
    </div>
  )
})
