import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { StoreContext } from '../../store'
import { Path } from '../../interfaces/view'
import Error from '../../components/Error'
import Loading from '../../components/loading/Loading'

export default observer(() => {
  const { authStore } = React.useContext(StoreContext)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setLoading] = useState(false)

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setError('')

    setLoading(true)
    try {
      await authStore.signIn(email, password)
    } catch (err: any) {
      setError(err.message)
    }
    setLoading(false)
  }

  return (
    <form
      className="flex flex-col p-8 sm:p-16 border-l border-r border-b"
      onSubmit={ onSubmit }>
      <label className="mb-4">
        <span className="hidden"> Email: </span>
        <input
          type="text"
          value={ email }
          className="border block w-full p-1 pl-2"
          placeholder="Email"
          onChange={ (e) => setEmail(e.target.value) } />
      </label>
      <label className="block mb-4">
        <span className="hidden"> Password: </span>
        <input
          type="password"
          value={ password }
          className="border block w-full p-1 pl-2"
          placeholder="Password"
          onChange={ (e) => setPassword(e.target.value) } />
      </label>

      { error
        && (
        <div className="mb-4">
          <Error msg={ error } />
        </div>
        ) }

      { isLoading
        && (
        <div className="mb-4">
          <Loading />
        </div>
        ) }

      { !isLoading
        && (
        <button
          type="submit"
          className={ `
          p-1 mb-8
          bg-emerald-600 text-white
          font-medium
        ` }>
          Sign In
        </button>
        ) }

      <a
        className="underline text-center text-gray-700"
        href={ Path.RESET_PASSWORD }>
        Forgot your password?
      </a>
    </form>
  )
})
