import React, {
  useContext,
  useEffect,
  useState,
} from 'react'

import { Storage } from 'aws-amplify'
import { observer } from 'mobx-react-lite'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faDownload, faTrash } from '@fortawesome/free-solid-svg-icons'
import { S3ProviderListOutputItem } from '@aws-amplify/storage'
import { StoreContext } from '../../store'
import Upload from '../../components/upload/Upload'
import AlertBox from '../../components/modal/AlertBox'
import Loading from '../../components/loading/Loading'
import { GraphFileName } from '../../interfaces'
import { validators } from '../../components/upload/utils'
import ErrorToast from '../../components/error-toast/ErrorToast'

interface File extends S3ProviderListOutputItem {
  url: string
}

export default observer(() => {
  const { viewStore } = useContext(StoreContext)

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [files, setFiles] = useState<File[]>([])
  const [selectedFile, setSelectedFile] = useState('')
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    fetchFiles()
  }, [])

  const fetchFiles = async () => {
    Storage.list('', { level: 'private' })
      .then((awsFiles) => {
        const filesWithUrls = awsFiles.map(async (file) => {
          const url = await Storage.get(file.key || '', { level: 'private' })
          return { ...file, url } as File
        })

        return Promise.all(filesWithUrls)
      })
      .then((filesWithUrls) => {
        setFiles(filesWithUrls)
        setIsLoading(false)
      })
      .catch(setError)
  }

  const getFileValidator = (key: GraphFileName) => {
    if (validators.hasOwnProperty(key)) {
      return validators[key]
    }

    return () => ''
  }

  const fileLine = (file: File) => {
    return (
      <tr key={ file.key } className="border-b border-gray-800">
        { /* file name */ }
        <td className="p-2 pl-6">
          { file.key }
        </td>

        { /* last updated */ }
        <td className="text-center">
          { file.lastModified?.toDateString() }
        </td>

        { /* file size */ }
        <td className="text-center pr-6">
          { file.size }
        </td>

        { /* upload */ }
        <td>
          <Upload
            title="upload"
            className="mr-4"
            fileName={ file.key || '' }
            onUploadDone={ () => fetchFiles() }
            setError={ (err) => setError(err) }
            showAsIcon
            validateFileContent={ getFileValidator(file.key as GraphFileName) } />
        </td>

        { /* download */ }
        <td>
          <a href={ file.url }>
            <FontAwesomeIcon
              title="download"
              icon={ faDownload }
              color="white"
              className="cursor-pointer mr-4" />
          </a>
        </td>

        { /* delete */ }
        <td>
          <FontAwesomeIcon
            title="delete"
            icon={ faTrash }
            color="white"
            className="cursor-pointer"
            onClick={ () => {
              setSelectedFile(file.key || '')
              setShowModal(true)
            } } />
        </td>
      </tr>
    )
  }

  return (
    <div className="bg-gray-900 text-white h-screen">
      { /* Sidebar */ }
      <h1 className={ `
        p-4 px-6 text-xl leading-7 font-semibold
        border-b-2 border-gray-800
      ` }>
        { !viewStore.isSidebarVisible
          && (
          <FontAwesomeIcon
            icon={ faBars }
            color="white"
            className="cursor-pointer mr-4"
            onClick={ () => viewStore.showSidebar() } />
          ) }
        Project files
      </h1>

      { /* Error toast */ }
      { error
        && (
        <ErrorToast
          error={ error }
          onDismiss={ () => setError('') } />
        ) }

      { /* Delete confirmation modal */ }
      { showModal
        && (
        <AlertBox
          content={ `Do you want to delete file ${selectedFile}?` }
          cancelText="Cancel"
          confirmText="Delete"
          onCancel={ () => setShowModal(false) }
          onConfirm={ () => {
            Storage.remove(selectedFile, { level: 'private' })
            setFiles(files.filter(({ key }) => key !== selectedFile))
            setShowModal(false)
            setSelectedFile('')
          } } />
        ) }

      { /* Table */ }
      { isLoading
        ? (
          <div className="relative top-1/4">
            <Loading />
          </div>
        )
        : (
          <div className="border-b-2 border-gray-800">
            <table className="w-full">
              <thead className="border-b border-gray-800">
                <tr className="text-sm uppercase">
                  <th className="text-left pl-6 p-4">File</th>
                  <th>Last updated</th>
                  <th className="pr-6">Size</th>
                </tr>
              </thead>
              <tbody>
                { files.map(fileLine) }
              </tbody>
            </table>
          </div>
        ) }
    </div>
  )
})
