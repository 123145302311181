import { makeAutoObservable } from 'mobx'
import View, { Path } from '../interfaces/view'

export default class ViewStore {
  currentView: View = {
    name: 'Home',
    path: Path.HOME,
  }

  isSidebarVisible = true

  constructor() {
    makeAutoObservable(this)
  }

  get currentPath(): Path {
    return this.currentView.path
  }

  hideSidebar() {
    this.isSidebarVisible = false
  }

  setView(view: View) {
    this.currentView = view
  }

  showContact() {
    this.setView({
      name: 'Contact',
      path: Path.CONTACT,
    })
  }

  showHiring() {
    this.setView({
      name: 'Hiring',
      path: Path.HIRING,
    })
  }

  showHome() {
    this.setView({
      name: 'Home',
      path: Path.HOME,
    })
  }

  showProduct() {
    this.setView({
      name: 'Product',
      path: Path.PRODUCT,
    })
  }

  showProjectData() {
    this.setView({
      name: 'ProjectData',
      path: Path.PROJECT_DATA,
    })
  }

  showSidebar() {
    this.isSidebarVisible = true
  }

  showTeam() {
    this.setView({
      name: 'Team',
      path: Path.TEAM,
    })
  }
}
