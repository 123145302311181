import './home.scss'
import awsLogo from '../../assets/home/aws-logo.png'
import democratizingGameDataScience from '../../assets/home/Democratizing-Game-Data-Science.png'
import icon1Homepage from '../../assets/home/icon1-homepage.png'
import icon2Homepage from '../../assets/home/icon2-homepage.png'
import icon3Homepage from '../../assets/home/icon3-homepage.png'
import KSlogo from '../../assets/home/KS-logo.png'
import MediaLogoBloomberg from '../../assets/home/Media-logo--bloomberg.png'
import MediaLogoEconomicTimes from '../../assets/home/Media-logo--economic-times.png'
import MediaLogoJapantimes from '../../assets/home/Media-logo--japantimes.png'
import MediaLogoSinc from '../../assets/home/Media-logo--Sinc.png'
import MediaLogoQuratz from '../../assets/home/Media-logo-Quratz.png'
import pocketGamerLogo from '../../assets/home/pocket-gamer-logo.png'
import sskkLogo from '../../assets/home/sskk-logo.png'
import TopHeader from '../../components/top-header/TopHeader'

export default function Home() {
  return (
    <div className="home">
      <TopHeader />
      { /* // <!-- First section --> */ }
      <div className="first-section section">
        <div className="demo-gds">
          <img
            src={ democratizingGameDataScience }
            alt="Democratizing Game Data Science" />
          <p className="header-text">
            YOKOZUNA data, a state-of-the-art
            machine learning engine that
            predicts the behavior of each individual user
          </p>
          <a href="/contact" className="button">Try it now</a>
        </div>
        <div className="explanation">
          <div className="explanation-content">
            <h4>Behavioral Prediction</h4>
            <p className="content-left">
              A prediction platform that uses next-gen AI algorithms
              to move game development into the future.
            </p>
          </div>
          <div className="explanation-content">
            <h4>Big Data</h4>
            <p className="content-left">
              Utilizing the latest techniques in big data processing and cloud computing,
              YOKOZUNA data scales to datasets of any size.
            </p>
          </div>
          <div className="explanation-content">
            <h4>Machine Learning</h4>
            <p className="content-left">
              Machine learning provides continuous improvement
              and increasingly accurate predictions.
            </p>
          </div>
        </div>
      </div>

      { /* // <!-- Why Yokozuna data --> */ }
      <div className="why-section section">
        <h2 className="title-text">Why Yokozuna Data?</h2>
        <div className="body">
          <div className="info-container">
            <img src={ icon1Homepage } alt="" />
            <h4>Develop games based on data</h4>
            <p>
              YOKOZUNA data reveals player patterns and powerful
              prediction insights that should boost data-driven
              game development.
            </p>
          </div>
          <div className="info-container">
            <img src={ icon2Homepage } alt="" />
            <h4>Player log-in and game dynamics</h4>
            <p>
              Understand the player dynamics of your games.
              What is the log-in probability of a given user?
              When will their next login take place?
              Implementing actions based on data is the best
              way to keep your players engaged.
            </p>
          </div>
          <div className="info-container">
            <img src={ icon3Homepage } alt="" />
            <h4>Personalized notifications and promotions</h4>
            <p>
              Send targeted players the right message
              at the right moment and make the most of
              your promotions thanks to the YOKOZUNA data
              notification system.
            </p>
          </div>
        </div>
      </div>

      { /* <!-- Yokozuna data in the news --> */ }
      <div className="yd-news-section section">
        <h2 className="title">Yokozuna data in the news</h2>
        <div className="body">
          <div className="row row-main">
            <div>
              <img
                src={ MediaLogoBloomberg }
                alt="Bloomberg logo" />
              <p>
                “Game makers are
                profiling players to keep
                them hooked”
              </p>
            </div>
            <div>
              <img
                src={ MediaLogoSinc }
                alt="Sinc logo" />
              <p>
                “An algorithm that knows
                when you'll get bored with
                your favourite video game”
              </p>
            </div>
            <div>
              <img
                src={ MediaLogoQuratz }
                alt="Quratz logo" />
              <p>
                “The next generation of video games will use
                AI to personalize your experience”
              </p>
            </div>
          </div>

          <div className="row row-main">
            <div>
              <img
                src={ MediaLogoJapantimes }
                alt="Japan Times logo" />
              <p>
                “Game makers deploy
                deep-learning AI algorithms
                to keep players coming back
                for more”
              </p>
            </div>
            <div>
              <img
                src={ pocketGamerLogo }
                alt="Pocket gamer logo" />
              <p>
                “Data scientists are already working
                on ways to leverage historical data
                to create a model for prediction of
                when a player will stop playing.”
              </p>
            </div>
            <div>
              <img
                src={ MediaLogoEconomicTimes }
                alt="Quratz logo" />
              <p>
                “This algorithm can
                predict users' affinity
                towards video games”
              </p>
            </div>
          </div>
        </div>
      </div>

      { /* <!-- Partners --> */ }
      <div className="partners-section">
        <p className="title">Our partners</p>
        <div className="body">
          <a href="https://aws.amazon.com/">
            <img
              src={ awsLogo }
              alt="AWS Logo" />
          </a>
          <a href="https://www.siliconstudio.co.jp/en/">
            <img
              src={ sskkLogo }
              alt="Silicon studio Logo" />
          </a>
        </div>
      </div>

      { /* <!-- Who's behind Yokozuna Data? --> */ }
      <div className="keywords-section">
        <h3 className="title">Who's behind Yokozuna Data?</h3>
        <div className="body">
          <a href="https://www.keywordsstudios.com/">
            <img
              src={ KSlogo }
              alt="Keywords Studios logo" />
          </a>
          <p>©Keywords Studios., all rights reserved.</p>
        </div>
      </div>
    </div>
  )
}
