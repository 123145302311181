/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react'

import { observer } from 'mobx-react-lite'

interface AlertBoxProps {
  content: string
  cancelText: string
  confirmText: string
  onCancel: () => void
  onConfirm: () => void
}

export default observer(({
  content,
  cancelText,
  confirmText,
  onCancel,
  onConfirm,
}: AlertBoxProps) => {
  const handler = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      onCancel()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handler)
    return () => window.removeEventListener('keydown', handler)
  }, [])

  return (
    <div
      className={ `
        absolute inset-0 z-50 h-screen w-screen
        bg-gray-800 bg-opacity-50
        flex items-center justify-center
      ` }
      onClick={ () => onCancel() }
      role="button"
      tabIndex={ 0 }>
      <div className="bg-gray-900 p-6 border-2 border-gray-700">
        <p className="mt-0">
          { content }
        </p>
        <div className="flex justify-around">
          <button
            className="bg-gray-700 p-1 px-2"
            onClick={ () => onCancel() }>
            { cancelText }
          </button>
          <button
            className="bg-gray-700 p-1 px-2"
            onClick={ () => onConfirm() }>
            { confirmText }
          </button>
        </div>
      </div>
    </div>
  )
})
