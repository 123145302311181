import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { StoreContext } from '../../store'
import { Path } from '../../interfaces/view'
import Error from '../../components/Error'
import Loading from '../../components/loading/Loading'

interface Props {
  email: string
}

export default observer(({ email }: Props) => {
  const { authStore } = React.useContext(StoreContext)

  const [code, setCode] = useState('')
  const [newPwd, setNewPwd] = useState('')
  const [confirmNewPwd, setConfirmNewPwd] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setLoading] = useState(false)

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setError('')

    setLoading(true)
    try {
      await authStore.forgotPasswordSubmit(email, code, newPwd)
    } catch (err: any) {
      setError(err.message)
    }
    setLoading(false)
  }

  return (
    <div
      className={ `
        mt-16 max-w-md mx-auto
        flex flex-col
        p-16 border
      ` }>
      <form
        onSubmit={ onSubmit }>
        <label className="block mb-4">
          <span className="hidden">Code:</span>
          <input
            type="text"
            value={ code }
            className="border block w-full p-1 pl-2"
            placeholder="Code"
            onChange={ (e) => setCode(e.target.value) } />
        </label>

        <label className="block mb-4">
          <span className="hidden">New password:</span>
          <input
            type="password"
            value={ newPwd }
            className="border block w-full p-1 pl-2"
            placeholder="New password"
            onChange={ (e) => setNewPwd(e.target.value) } />
        </label>

        <label className="block mb-4">
          <span className="hidden">Confirm password:</span>
          <input
            type="password"
            value={ confirmNewPwd }
            className="border block w-full p-1 pl-2"
            placeholder="Confirm password"
            onChange={ (e) => setConfirmNewPwd(e.target.value) } />
        </label>

        { error
          && (
          <div className="mb-4">
            <Error msg={ error } />
          </div>
          ) }

        { isLoading
          && (
          <div className="mb-4">
            <Loading />
          </div>
          ) }

        { !isLoading
          && (
          <button
            type="submit"
            className={ `
            w-full p-1 mb-8
            bg-emerald-600 text-white
            font-medium
          ` }>
            Submit
          </button>
          ) }
      </form>

      <a
        className="underline text-center text-gray-700"
        href={ Path.SIGN_IN }>
        Resend Code
      </a>
    </div>
  )
})
