import './contact.scss'
import Footer from '../../components/footer/Footer'
import TopHeader from '../../components/top-header/TopHeader'

export default function Contact() {
  return (
    <div className="contact">
      <TopHeader />

      <div className="body">
        <div className="contact-body main-content">
          <div className="office">
            <h3 className="content-title">Our office</h3>
            <p>
              9F, Asahi Seimei Daitabashi Bldg. <br />
              1-22-19 Izumi, Suginami  <br />
              Tokyo, 168-0063 Japan

            </p>
            <p>info@yokozunadata.com</p>
          </div>

          { /* <!-- we are hiring --> */ }
          <div className="hiring">
            <h3 className="content-title">We are hiring</h3>
            <p>
              We’re always looking for talented engineers and
              brilliant data scientists who think big and
              are passionate about game data.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}
