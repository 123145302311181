import axios from 'axios'
import { Storage } from 'aws-amplify'
import { makeAutoObservable } from 'mobx'
import { GraphFileName } from '../interfaces'

export default class DashboardStore {
  abnormalPlayersData: string[] = []

  abnormalPlayersUrl = ''

  dauData: string | null = ''

  dailyPurchasesData: string | null = ''

  ltvData: string | null = ''

  mauData: string | null = ''

  retentionData: string | null = ''

  survivalData: string | null = ''

  constructor() {
    makeAutoObservable(this)
  }

  async loadData() {
    this.fetchAbnormalPlayers()
    this.fetchDau()
    this.fetchLtv()
    this.fetchMau()
    this.fetchPurchases()
    this.fetchRetention()
    this.fetchSurvival()
  }

  get mau() {
    if (this.mauData === null || this.mauData === '') {
      return 0
    }

    const rows = this.mauData.trim().split('\n')
    const lastRow = rows[rows.length - 1]

    return parseInt(lastRow.split(',')[1], 10)
  }

  get bestMonth() {
    const best = {
      month: '',
      value: 0,
    }

    if (this.mauData === null || this.mauData === '') {
      return best
    }

    const rows = this.mauData.trim().split('\n')
    rows.forEach((row) => {
      const [date, value] = row.split(',')
      const mau = parseInt(value, 10)
      if (mau > best.value) {
        best.value = mau
        const [_day, month, _date, year] = new Date(date).toDateString().split(' ')
        best.month = `${month} ${year}`
      }
    })

    return best
  }

  get bestDay() {
    const best = {
      day: '',
      value: 0,
    }

    if (this.dauData === null || this.dauData === '') {
      return best
    }

    const rows = this.dauData.trim().split('\n')
    rows.forEach((row) => {
      const [d, value] = row.split(',')
      const dau = parseInt(value, 10)
      if (dau > best.value) {
        best.value = dau
        const [_day, month, date, year] = new Date(d).toDateString().split(' ')
        best.day = `${month} ${date}, ${year}`
      }
    })

    return best
  }

  get bestDayOfWeek() {
    const best = {
      day: '',
      avg: 0,
    }

    if (this.dauData === null || this.dauData === '') {
      return best
    }

    const rows = this.dauData.trim().split('\n')
    const days: number[] = []

    rows.forEach((row) => {
      const [d, value] = row.split(',')
      const day = new Date(d).getDay()

      if (!days[day]) {
        days[day] = 0
      }

      // we calculate the average
      days[day] = (days[day] + parseInt(value, 10)) / 2
    })

    const daysString = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]

    // we search for the best day
    days.forEach((avg, index) => {
      if (avg > best.avg) {
        best.day = daysString[index]
        best.avg = Math.trunc(avg)
      }
    })

    return best
  }

  get dau() {
    if (this.dauData === null || this.dauData === '') {
      return 0
    }

    const rows = this.dauData.trim().split('\n')
    const lastRow = rows[rows.length - 1]

    return parseInt(lastRow.split(',')[1], 10)
  }

  setData(
    graph: GraphFileName,
    data: string | null,
    url?: string,
  ) {
    switch (graph) {
      case GraphFileName.ANOMALIES:
        if (data && url) {
          this.abnormalPlayersData = data.split('\n')
          this.abnormalPlayersUrl = url
        }
        break
      case GraphFileName.DAU:
        this.dauData = data
        break
      case GraphFileName.LTV:
        this.ltvData = data
        break
      case GraphFileName.MAU:
        this.mauData = data
        break
      case GraphFileName.PURCHASES:
        this.dailyPurchasesData = data
        break
      case GraphFileName.RETENTION:
        this.retentionData = data
        break
      case GraphFileName.SURVIVAL_10_DAYS:
        this.survivalData = data
        break
      default:
        console.warn(`Unknown graph: ${graph}`)
    }
  }

  async fetchData(graph: GraphFileName) {
    const url = await Storage.get(
      graph,
      { level: 'private' },
    )

    try {
      const { data } = await axios.get(
        url,
        { responseType: 'blob' },
      )

      const csv = await data.text()
      this.setData(graph, csv, url)
    } catch (err: any) {
      const { response } = err
      if (response && response.status === 404) {
        this.setData(graph, null)
      }
    }
  }

  async fetchAbnormalPlayers() {
    this.fetchData(GraphFileName.ANOMALIES)
  }

  async fetchDau() {
    this.fetchData(GraphFileName.DAU)
  }

  async fetchLtv() {
    this.fetchData(GraphFileName.LTV)
  }

  async fetchMau() {
    this.fetchData(GraphFileName.MAU)
  }

  async fetchPurchases() {
    this.fetchData(GraphFileName.PURCHASES)
  }

  async fetchRetention() {
    this.fetchData(GraphFileName.RETENTION)
  }

  async fetchSurvival() {
    this.fetchData(GraphFileName.SURVIVAL_10_DAYS)
  }
}
