import {
  action,
  makeAutoObservable,
  when,
} from 'mobx'
import { IAuth } from '../backend/Auth'
import {
  AuthException,
  User,
} from '../interfaces'
import { Path } from '../interfaces/view'
import ViewStore from './view'

export default class AuthStore {
  user: null | User = null

  isFetchingUser = true

  Auth

  viewStore

  constructor(viewStore: ViewStore, Auth: IAuth) {
    makeAutoObservable(this, {
      showConfirmResetPassword: action,
      showConfirmSignUp: action,
      showDashboard: action,
      showSignIn: action,
      showSignUp: action,
      showResetPassword: action,
    })

    this.viewStore = viewStore
    this.Auth = Auth
    this.fetchUser()
  }

  private async fetchUser() {
    this.setIsFetchingUser(true)
    try {
      const user = await this.Auth.currentAuthenticatedUser()
      this.setUser(user)
      this.setIsFetchingUser(false)
    } catch (err) {
      this.setIsFetchingUser(false)
    }
  }

  get isAuthenticated() {
    return this.user !== null
  }

  showConfirmResetPassword(email: string) {
    this.viewStore.setView({
      name: 'ConfirmResetPassword',
      path: Path.CONFIRM_RESET_PASSWORD,
      state: { email },
    })
  }

  showConfirmSignUp(email: string) {
    this.viewStore.setView({
      name: 'ConfirmSignUp',
      path: Path.CONFIRM_SIGN_UP,
      state: { email },
    })
  }

  async showDashboard() {
    this.viewStore.setView({
      name: 'Dashboard',
      path: Path.DASHBOARD,
    })

    await when(() => !this.isFetchingUser)
    if (!this.isAuthenticated) {
      this.showSignIn()
    }
  }

  async showSignIn() {
    this.viewStore.setView({
      name: 'SignIn',
      path: Path.SIGN_IN,
    })

    await when(() => !this.isFetchingUser)
    if (this.isAuthenticated) {
      this.showDashboard()
    }
  }

  showSignUp() {
    this.viewStore.setView({
      name: 'SignUp',
      path: Path.SIGN_UP,
    })
  }

  showResetPassword() {
    this.viewStore.setView({
      name: 'ResetPassword',
      path: Path.RESET_PASSWORD,
    })
  }

  setIsFetchingUser(value: boolean) {
    this.isFetchingUser = value
  }

  setUser(user: User | null) {
    this.user = user
  }

  async signIn(
    email: string,
    password: string,
  ) {
    try {
      const user = await this.Auth.signIn(email, password)
      this.setUser(user)
      this.showDashboard()
    } catch (err: any) {
      if (err.name === AuthException.UserNotConfirmedException) {
        await this.resendSignUp(email)
        this.showConfirmSignUp(email)
      } else {
        throw (err)
      }
    }
  }

  async signOut() {
    await this.Auth.signOut()
    this.setUser(null)
    this.viewStore.showHome()
  }

  async confirmSignUp(
    email: string,
    code: string,
  ) {
    await this.Auth.confirmSignUp(email, code)
    await this.fetchUser()
    this.showDashboard()
  }

  async resendSignUp(email: string) {
    await this.Auth.resendSignUp(email)
  }

  async signUp(
    email: string,
    password: string,
  ) {
    const user = await this.Auth.signUp(email, password)
    this.setUser(user)

    if (!user.email_verified) {
      this.showConfirmSignUp(email)
    } else {
      this.viewStore.showHome()
    }
  }

  async forgotPassword(email: string) {
    await this.Auth.forgotPassword(email)
    this.showConfirmResetPassword(email)
  }

  async forgotPasswordSubmit(
    email: string,
    code: string,
    newPassword: string,
  ) {
    await this.Auth.forgotPasswordSubmit(
      email,
      code,
      newPassword,
    )
    this.showSignIn()
  }
}
