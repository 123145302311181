import { Auth as AwsAuth } from 'aws-amplify'
import { User } from '../interfaces'

// the CognitoUser interface is so convoluted
// that I prefer using 'any' type
// and extract the properties that I need
const cognitoUserToUser = ({
  username,
  attributes,
}: any): User => {
  return { username, ...attributes }
}

export type IAuth = typeof Auth

export const Auth = {
  confirmSignUp: AwsAuth.confirmSignUp.bind(AwsAuth),

  currentAuthenticatedUser: async (): Promise<User> => {
    const cognitoUser = await AwsAuth.currentAuthenticatedUser({ bypassCache: true })
    return cognitoUserToUser(cognitoUser)
  },

  forgotPassword: AwsAuth.forgotPassword.bind(AwsAuth),

  forgotPasswordSubmit: AwsAuth.forgotPasswordSubmit.bind(AwsAuth),

  resendSignUp: AwsAuth.resendSignUp.bind(AwsAuth),

  signIn: async (
    email: string,
    password: string,
  ): Promise<User> => {
    const cognitoUser = await AwsAuth.signIn(email, password)

    return cognitoUserToUser(cognitoUser)
  },

  signOut: AwsAuth.signOut.bind(AwsAuth),

  signUp: async (
    email: string,
    password: string,
  ): Promise<User> => {
    const { user } = await AwsAuth.signUp({
      username: email,
      password,
    })

    return cognitoUserToUser(user)
  },
}
