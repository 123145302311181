import React from 'react'
import { IAuth } from '../backend/Auth'
import AuthStore from './auth'
import DashboardStore from './dashboard'
import ViewStore from './view'

export default class Store {
  authStore

  dashboardStore

  viewStore

  constructor(Auth: IAuth) {
    this.viewStore = new ViewStore()
    this.authStore = new AuthStore(this.viewStore, Auth)
    this.dashboardStore = new DashboardStore()
  }
}

export const StoreContext = React.createContext(<Store>{})
