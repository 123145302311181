import './Loading.scss'

interface Props {
  className?: string
  resource?: string
  size?: string
}

const Loading = ({
  className,
  resource,
  size,
}: Props) => {
  if (resource) {
    return (
      <div className={ className }>
        Loading
        { `${resource}...` }
      </div>
    )
  }

  return (
    <div className={ `${className} loading` }>
      <div
        className="loader"
        style={ {
          height: size,
          width: size,
        } } />
    </div>
  )
}

Loading.defaultProps = {
  className: '',
  resource: '',
  size: '30px',
}

export default Loading
