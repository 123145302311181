import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons'
import { observer } from 'mobx-react-lite'
import { Storage } from 'aws-amplify'
import Loading from '../loading/Loading'

interface UploadProps {
  className?: string
  fileName: string
  onUploadDone: () => void
  setError: (error: string) => void
  showAsIcon?: boolean
  title?: string
  validateFileContent: (content: string) => string
}

export default observer(({
  className,
  fileName,
  onUploadDone,
  setError,
  showAsIcon,
  title,
  validateFileContent,
}: UploadProps) => {
  const fileInput = React.createRef<HTMLInputElement>()
  const [isUploading, setIsUploading] = useState(false)
  const [uploaded, setUploaded] = useState(false)

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target
    setError('')

    if (files && files[0]) {
      const file = files[0]

      const content = await file.text()
      const errorMsg = validateFileContent(content)

      if (errorMsg !== '') {
        return setError(errorMsg)
      }

      if (file.type !== 'text/csv') {
        return setError('File must be CSV')
      }

      try {
        setIsUploading(true)
        await Storage.put(fileName, file, {
          level: 'private',
        })
        onUploadDone()
        setUploaded(true)
        setTimeout(() => setUploaded(false), 3000)
      } catch (err) {
        console.log(err)
      }

      setIsUploading(false)
    }
  }

  if (isUploading) {
    return <Loading size="20px" />
  }

  if (uploaded) {
    return (
      <p
        className={ `
          m-0 text-green-600 uppercase
          text-sm leading-5 font-semibold
        ` }>
        Uploaded
      </p>
    )
  }

  if (showAsIcon) {
    return (
      <div className={ `${className}` }>
        <input
          ref={ fileInput }
          type="file"
          accept="text/csv"
          onChange={ onChange }
          className="hidden" />
        <FontAwesomeIcon
          title={ title }
          icon={ faUpload }
          color="white"
          className="cursor-pointer"
          onClick={ () => fileInput.current?.click() } />
      </div>
    )
  }

  return (
    <div className={ `bg-gray-700 border-0 ${className}` }>
      <input
        ref={ fileInput }
        type="file"
        accept="text/csv"
        onChange={ onChange }
        className="hidden" />
      <button
        onClick={ () => fileInput.current?.click() }
        className="p-1 px-2">
        Upload
      </button>
    </div>
  )
})
