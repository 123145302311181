import React, { createContext, useReducer } from 'react'

import AppReducer from './AppReducer'
import { State } from '../interfaces'

const initialState: State = {
  dashboard: {
    dau: { table: [] },
    dailyNewUsers: { table: [] },
    dailyPurchases: { table: [] },
    mau: 0,
  },
  showSidebar: false,
}

interface Context {
  state: State,
  dispatch: React.Dispatch<any> | null
}

export const GlobalContext = createContext<Context>({
  state: initialState,
  dispatch: null,
})

function GlobalProvider({ children }: { children: any }) {
  const [state, dispatch] = useReducer(AppReducer, initialState)

  return (
    <GlobalContext.Provider value={ {
      state,
      dispatch,
    } }>
      { children }
    </GlobalContext.Provider>
  )
}

export default GlobalProvider
