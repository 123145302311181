import { VisualizationSpec } from 'react-vega'

export const dauSpec = (
  data: string,
): VisualizationSpec => {
  return {
    width: 'container',
    height: 200,
    mark: {
      type: 'line',
      point: true,
      interpolate: 'monotone',
      tooltip: true,
    },
    encoding: {
      x: {
        field: 'day',
        timeUnit: 'yearmonthdate',
        title: '',
      },
      y: {
        field: 'DAU',
        type: 'quantitative',
        title: 'DAU',
      },
    },
    data: {
      values: data,
      format: {
        type: 'csv',
      },
    },
  }
}

export const mauSpec = (
  data: string,
): VisualizationSpec => {
  return {
    width: 'container',
    height: 200,
    mark: {
      type: 'line',
      point: true,
      tooltip: true,
    },
    encoding: {
      x: {
        field: 'day',
        timeUnit: 'yearmonth',
        title: '',
      },
      y: {
        field: 'MAU',
        type: 'quantitative',
        title: 'MAU',
      },
    },
    data: {
      values: data,
      format: {
        type: 'csv',
      },
    },
  }
}

export const dailyPurchasesSpec = (
  data: string,
): VisualizationSpec => {
  return {
    width: 'container',
    height: 200,
    mark: {
      type: 'line',
      point: true,
      interpolate: 'monotone',
      tooltip: true,
    },
    encoding: {
      x: {
        field: 'day',
        timeUnit: 'yearmonthdate',
        title: '',
      },
      y: {
        field: 'sales',
        type: 'quantitative',
        title: 'Daily Purchases ($)',
      },
    },
    data: {
      values: data,
      format: {
        type: 'csv',
      },
    },
  }
}

export const survivalSpec = (
  data: string,
): VisualizationSpec => {
  return {
    width: 'container',
    height: 200,
    mark: {
      type: 'bar',
      tooltip: true,
    },
    encoding: {
      x: {
        field: 'type',
        title: '',
      },
      y: {
        field: 'value',
        type: 'quantitative',
        title: 'Users',
      },
    },
    data: {
      values: data,
      format: {
        type: 'csv',
      },
    },
  }
}

export const survivalLastDaySpec = (
  data: string,
): VisualizationSpec => {
  return {
    width: 'container',
    height: 200,
    mark: {
      type: 'bar',
      tooltip: true,
    },
    encoding: {
      x: {
        field: 'type',
        title: '',
      },
      y: {
        field: 'value',
        type: 'quantitative',
        title: 'Users',
      },
    },
    data: {
      values: data,
      format: {
        type: 'csv',
      },
    },
  }
}

export const LTVSpec = (
  data: string,
): VisualizationSpec => {
  return {
    width: 'container',
    height: 200,
    mark: {
      type: 'bar',
      tooltip: true,
    },
    encoding: {
      x: {
        field: 'type',
        title: '',
      },
      y: {
        field: 'value',
        type: 'quantitative',
        title: 'LTV',
        stack: null,
      },
      color: {
        field: 'nbDays',
        title: 'Period (days)',
      },
      order: { field: 'sortOrder' },
    },
    transform: [{
      calculate: 'if(toNumber(datum.nbDays) === 120, 1, 0)',
      as: 'sortOrder',
    }],
    data: {
      values: data,
      format: {
        type: 'csv',
      },
    },
  }
}
