import React, {
  useState,
} from 'react'

import { VisualizationSpec } from 'react-vega'
import { observer } from 'mobx-react-lite'
import DatePicker from 'react-datepicker'
import { Vega } from '../graph-card/GraphCard'
import Upload from '../../components/upload/Upload'
import { filterByDates, getDateRange } from './utils'

import 'react-datepicker/dist/react-datepicker.css'
import './DatePicker.scss'

interface GraphCardProps {
  data: string | null
  fileName: string
  onUploadDone: () => void
  setError: (error: string) => void
  spec: VisualizationSpec
  title: string
  validateFileContent: (content: string) => string
}

export default observer(({
  data,
  fileName,
  onUploadDone,
  setError,
  spec,
  title,
  validateFileContent,
}: GraphCardProps) => {
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [filteredData, setFilteredData] = useState<string | null>(null)

  React.useEffect(() => {
    if (data && data !== '') {
      const [start, end] = getDateRange(data)
      setStartDate(start)
      setEndDate(end)
      setFilteredData(filterByDates(start, end, data))
    }
  }, [data])

  const cardHeader = `
    flex justify-between items-center
    bg-gray-800 p-4
  `

  return (
    <div className={ `
      flex-auto w-full mb-8
      border-t-4 border-emerald-400
      bg-white
    ` }>
      <div className={ cardHeader }>
        <h2 className="uppercase text-sm leading-5 font-semibold">
          { title }
        </h2>

        <div className="flex">
          <DatePicker
            className="mr-4 bg-gray-700 border-0"
            dateFormat="dd/MM/yyyy"
            selected={ startDate }
            onChange={ (date) => {
              if (date) {
                setStartDate(date)
              }
            } } />
          <DatePicker
            className="bg-gray-700 border-0"
            dateFormat="dd/MM/yyyy"
            selected={ endDate }
            onChange={ (date) => {
              if (date) {
                setEndDate(date)
              }
            } } />
        </div>

        <Upload
          fileName={ fileName }
          onUploadDone={ () => onUploadDone() }
          setError={ setError }
          validateFileContent={ validateFileContent } />
      </div>
      <div className="p-2 xl:p-6">
        <Vega
          data={ filteredData }
          spec={ spec } />
      </div>
    </div>
  )
})
