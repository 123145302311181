/* eslint-disable no-unused-vars */
export interface User {
  username: string
  email: string
  sub: string
  email_verified: boolean
}

// AuthException doesn't seem to be defined
// in the official code repo...
// https://www.npmjs.com/package/amazon-cognito-identity-js
export enum AuthException {
  UserNotConfirmedException = 'UserNotConfirmedException'
}

// we define AuthError here because
// it doesn't seem to be exported
// https://aws-amplify.github.io/amplify-js/api/classes/autherror.html
export interface AuthError {
  log: string
  message: string
  name: AuthException
  stack: string
}

export type notificationType = 'danger' | 'success' | 'warning' | 'info' | 'default'

// eslint-disable-next-line no-shadow
export enum ActionType {
  CLOSE_SIDEBAR = 'CLOSE_SIDEBAR',
  SET_USER = 'SET_USER',
  SET_DAU = 'SET_DAU',
  SET_DAILY_PURCHASES = 'SET_DAILY_PURCHASES',
  SET_DAILY_NEW_USERS = 'SET_DAILY_NEW_USERS',
  SET_MAU = 'SET_MAU',
  TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR',
}

export interface Action {
  type: ActionType,
  payload: any
}

export interface DAURow {
  date: string,
  value: number
}

export interface DailyPurchasesRow {
  date: string,
  value: number
}

export interface DailyNewUsersRow {
  date: string,
  value: number
}

export interface DashboardState {
  dau: { [key: string]: DAURow[] }
  dailyPurchases: { [key: string]: DailyPurchasesRow[] }
  dailyNewUsers: { [key: string]: DailyNewUsersRow[] }
  mau: number
}

export enum GraphFileName {
  ANOMALIES = 'ANOMALIES.csv',
  DAU = 'DAU.csv',
  LTV = 'LTV.csv',
  MAU = 'MAU.csv',
  PURCHASES = 'PURCHASES.csv',
  RETENTION = 'RETENTION.csv',
  SURVIVAL_10_DAYS = 'SURVIVAL_10_DAYS.csv',
}

export interface RetentionData {
  date: string
  users: number
  'Day 1': number
  'Day 2': number
  'Day 3': number
  'Day 4': number
  'Day 5': number
  'Day 6': number
  'Day 7': number
  'Day 14': number
  'Day 28': number
  'Day 30': number
}

export interface State {
  dashboard: DashboardState
  showSidebar: boolean
}

export interface VegaData {
  [key: string]: {
    date: string
    value: number
  }[]
}
