import { Location } from 'history'
import history from 'history/browser'
import { autorun } from 'mobx'
import { Path } from './interfaces/view'
import Store from './store'

// we need to remove the trailing '/'
// because of the way AWS rewrite URLs
// e.g AWS will rewrite '/product' to '/product/'
function cleanPath(path: string) {
  const { length } = path
  if (length > 1 && path[length - 1] === '/') {
    return path.slice(0, path.length - 1)
  }
  return path
}

function handleRoutes(
  store: Store,
  location: Location,
) {
  let url
  let email
  const { authStore, viewStore } = store
  const path = cleanPath(location.pathname)

  switch (path) {
    case Path.CONTACT:
      viewStore.showContact()
      break
    case Path.CONFIRM_RESET_PASSWORD:
      url = new URL(window.location.href)
      email = url.searchParams.get('email')
      if (email) {
        authStore.showConfirmResetPassword(email)
      } else {
        authStore.showResetPassword()
      }
      break
    case Path.CONFIRM_SIGN_UP:
      url = new URL(window.location.href)
      email = url.searchParams.get('email')
      if (email) {
        authStore.showConfirmSignUp(email)
      } else {
        authStore.showSignIn()
      }
      break
    case Path.DASHBOARD:
      authStore.showDashboard()
      break
    case Path.HIRING:
      viewStore.showHiring()
      break
    case Path.HOME:
      viewStore.showHome()
      break
    case Path.PRODUCT:
      viewStore.showProduct()
      break
    case Path.PROJECT_DATA:
      viewStore.showProjectData()
      break
    case Path.SIGN_IN:
      authStore.showSignIn()
      break
    case Path.SIGN_UP:
      authStore.showSignUp()
      break
    case Path.TEAM:
      viewStore.showTeam()
      break
    case Path.RESET_PASSWORD:
      authStore.showResetPassword()
      break
    default:
      /* eslint-disable-next-line no-console */
      console.warn('Unknown url:', location)
  }
}

export function startRouter(store: Store) {
  handleRoutes(store, history.location)

  // update state on url change
  history.listen(({ location }) => {
    handleRoutes(store, location)
  })

  // update url on state changes
  autorun(() => {
    const view = store.viewStore.currentView

    if (view.path === window.location.pathname) {
      return
    }

    if (view.name === 'ConfirmSignUp') {
      const { email } = view.state
      return history.push(`${view.path}?email=${email}`)
    }

    if (view.name === 'ConfirmResetPassword') {
      const { email } = view.state
      return history.push(`${view.path}?email=${email}`)
    }

    history.push(view.path)
  })
}
