import React from 'react'

interface TermsProps {
  onCloseClick: () => void
}

export default function Terms({ onCloseClick }: TermsProps) {
  return (
    <div className="terms-of-use h-screen">
      <div className={ `
        bg-white border w-full h-full
        overflow-scroll
        ` }>
        <h2 className="m-4">
          Terms of Use (Last updated: 30/03/2018)
        </h2>
        <div className="m-4">
          <p className="font-bold my-2">
            IMPORTANT: PLEASE READ THIS YOKOZUNA DATA TERM OF USE (“AGREEMENT”) CAREFULLY
            BEFORE USING THE SERVICE “YOKOZUNA DATA” PROVIDED BY SILICON STUDIO CORPORATION (“SSC”)
            TO YOU FOR THE PURPOSE OF ANALYSIS OF APPLICATION UTILIZATION STATUS (“SERVICE”),
            SO THAT YOU MAY WELL UNDERSTAND THE TERMS AND CONDITIONS FOR OBTAINING A LICENSE
            TO USE THE SERVICE.
          </p>

          <p>
            * What is written in this Agreement will be the terms and conditions
            of the contract between you and SSC, concerning use of the Service.
            So, please retain a copy of this Agreement if necessary.
          </p>

          <h3>Article 1 (Purpose)</h3>
          <p>
            1.1 SSC shall provide the Services to the user (“User”)
            through an administration console, or other web page
            (collectively, the " Websites ".) provided by SSC related to such website,
            which the User shall use in compliance with this Agreement.
          </p>

          <p>
            1.2 The User agrees to all conditions set forth herein,
            as well as all applicable service descriptions,
            terms and directions (collectively, the “Service Descriptions”)
            separately provided by SSC to the User.
            Such Service Descriptions shall constitute a part of this Agreement.
          </p>

          <h3>Article 2 (Definitions)</h3>

          <p>
            In this Agreement, the following defined terms shall apply
            unless the context requires otherwise.
          </p>
          <p>(1) Application</p>

          <p>
            An application developed by the User,
            which is subject to the Services in the cloud.
          </p>
          <p>(2) Confidential Information</p>

          <p>
            All information reasonably regarded as confidential given the conditions of disclosure,
            disclosed whether in writing, oral or by other means from one of the Parties
            (the Disclosing Party) to the other Party (the Receiving Party),
            including the following information:
          </p>
          <p>(i) information related to the Services;</p>
          <p>(ii) all other undisclosed information.</p>
          <p>(3) Party</p>
          <p>One or both parties entering into this Agreement.</p>
          <p>(4) Program</p>
          <p>The program that SSC provides to User to introduce "YOKOZUNA Data".</p>

          <h3>Article 3 (Use of Services)</h3>
          <p>
            3.1 Continued use of the Services after such modification constitutes
            the User’s acceptance of the modification. If the User does not
            agree to the change to this Agreement, the User shall not use or access
            (or continue to use or access) the Services.
          </p>
          <p>
            3.2 Prior to the use or on using the Websites,
            the User shall represent and warrant to SSC that:
          </p>
          <p>
            (1) if the User is an individual (i.e., not an entity),
            the User is at least twenty (20) years old (or if under the age of twenty (20),
            the User shall have the consent of the User’s parent or guardian);
          </p>
          <p>
            (2) the User shall not violate applicable laws or this Agreement
            on using of or access to the Services; and
          </p>
          <p>
            (3) the User take full responsibility for the selection,
            use of and access to the Services.
          </p>

          <h3>Article 4 (Account and Password Management)</h3>
          <p>
            4.1 SSC shall issue an account (“Account”) and password (“Password”)
            to the User for use of the Services.
          </p>
          <p>
            4.2 The User shall not, under any circumstances,
            transfer or lend the Account and Password to a third party.
          </p>
          <p>
            4.3 The User shall make efforts to prevent improper use of the
            Account and Password while exercising strict control under its own responsibility.
          </p>

          <h3>Article 5 (Website Content)</h3>
          <p>
            5.1 The User shall use the materials displayed on the Websites,
            including, but not limited to, text, images and information in
            connection with the Services (collectively, the "Content"),
            in compliance with all rules related to the usage of Content
            displayed on the Websites, and shall not copy, reproduce,
            modify, translate, publish, broadcast, transmit, distribute,
            perform, upload, display, license, sell or otherwise exploit
            for any purposes whatsoever any Content without prior consent of SSC.
          </p>
          <p>
            5.2 The User may not modify, publish, transmit, sale, reproduce
            (except as expressly provided in this Article 5),
            create derivative works based on, distribute, perform, display,
            or in any way exploit, any of the software, materials,
            or Services in whole or in part.
          </p>
          <p>
            5.3 Copying or storing of any Content for any purpose
            other than the use of the Services is expressly prohibited
            without prior written consent of SSC. The User shall not link to
            the Websites without prior written consent of SSC.
          </p>

          <h3>Article 6 (Intellectual Property Rights)</h3>
          <p>
            6.1 SSC grants User non-exclusive and non-sublicensable right to
            use the Program in accordance with the method agreed upon
            between the User and SSC.
          </p>
          <p>
            6.2 The User may use the data specified in the previous paragraph
            and the Program on a nonexclusive basis, in the same condition
            in which they were provided by SSC, without any alteration or revision,
            and solely for the purpose of using the Services within the office of
            the User as provided, except as otherwise agreed upon between the Parties.
          </p>
          <p>
            6.3 The User shall not, without written consent of SSC,
            duplicate, alter, analyze, decompile or conduct reverse engineering,
            and the like, on the Services. In using the Services,
            the User shall not effect direct connections or settings
            that circumvent the Services (in whole or in part) provided by SSC,
            or that otherwise disable its tracking or observation functions.
          </p>
          <p>
            6.4 The User shall not engage in the activities which obstruct
            (or may obstruct) the Services performed by SSC.
          </p>
          <p>
            6.5 SSC may use the User’s name and logo in all publicity,
            advertising or other market activity. SSC shall not use the User’s
            name or logo in any manner that would adversely affect the User’s reputation.
          </p>

          <h3>Article 7 (Representation and Warranty)</h3>
          <p>
            7.1 EACH OF THE PARTIES HEREBY REPRESENTS AND
            WARRANTS THE FOLLOWING TO THE OTHER PARTY:
          </p>
          <p>
            (1) IT RETAINS THE FULL RIGHTS, CAPACITY AND
            AUTHORITY TO ENTER INTO THIS AGREEMENT.
          </p>
          <p>
            (2) ALL PERMISSIONS, AUTHORIZATIONS, APPROVALS AND AGREEMENTS
            NECESSARY UNDER APPLICABLE LAWS AND REGULATIONS TO ENTER INTO
            THIS AGREEMENT AND PERFORM OBLIGATIONS HEREUNDER ARE OBTAINED
            AND MAINTAINED THROUGHOUT THE TERM OF THIS AGREEMENT.
          </p>
          <p>7.2 THE USER HEREBY REPRESENTS AND WARRANTS THE FOLLOWING TO SSC:</p>
          <p>
            (1) THE USER, IN THE CASE OF BEING ORGANIZED AS A COMPANY
            OR INCORPORATED BODY, OBTAINS ALL APPROVALS NECESSARY
            TO ENTER INTO THIS AGREEMENT AND FOR ALL TRANSACTIONS RELATED
            TO THE SERVICES. THE USE OF THE SERVICES AND THE PERFORMANCE
            OF THE OBLIGATIONS HEREUNDER BY THE USER DOES NOT OR WILL
            NOT VIOLATE ANY LAWS, REGULATIONS OR LEGALLY BINDING COVENANTS OR CONTRACTS.
          </p>
          <p>
            (2) THE APPLICATION INCLUDES NO VIRUSES, TROJAN HORSES,
            TRAPDOORS, BACKDOORS, EASTER EGGS (HIDDEN FUNCTIONS),
            WORMS, TIME BOMBS, CANCELBOTS OR OTHER PROGRAM ROUTINES
            THAT MIGHT DAMAGE OR IMPEDE THE SERVICES, OR INTERCEPT
            SYSTEM DATA OR PERSONAL INFORMATION.
          </p>
          <p>
            (3) THE USER DOES NOT FALL UNDER THE CATEGORY OF GROUPS
            DETRIMENTAL TO CIVIL SOCIETY, INCLUDING BORYOKUDAN
            (ORGANIZED CRIME GROUPS), BORYOKUDAN KOUSEIIN/JUN-KOUSEIIN
            (MEMBERS/QUASI-MEMBERS OF BORYOKUDAN),
            BORYOKUDAN KANREN KIGYOU (ORGANIZED CRIME-RELATED COMPANIES),
            SOUKAIYA (CORPORATE RACKETEERING GROUPS),
            SHAKAI UNDOU HYOUBOUGORO (RACKETEERING GROUPS DISGUISED AS SOCIAL PROTEST MOVEMENTS),
            SEIJI KATSUDOU HYOUBOUGORO (RACKETEERING GROUPS DISGUISED AS POLITICAL ACTIVISTS),
            TOKUSHU CHINOU BORYOKUDAN (RACKETEERING GROUPS THAT USE SPECIALIZED EXPERTISE
            OR INFORMATION FOR ILLICIT PURPOSES), OR ANY OTHER SUCH GROUPS.
          </p>
          <p>(4) THE USER DOES NOT VIOLATE ANY LAW OR REGULATION.</p>
          <p>
            (5) THE USER DOES NOT USE THE SERVICES IN ANY MANNER
            THAT IS HARMFUL, FRAUDULENT, DECEPTIVE, THREATENING,
            ABUSIVE, HARASSING, TORTIOUS, DEFAMATORY, VULGAR, OBSCENE,
            LIBELOUS, OR OTHERWISE CONSIDERED BY SSC AS OBJECTIONABLE.
          </p>

          <h3>Article 8 (Indemnification)</h3>
          <p>
            8.1 Each of the Parties indemnifies and hold harmless
            the other Party against all claims, loss, damages, taxes,
            reasonable attorney’s fees and litigation costs arising
            out of breach of warranty by either of the Parties,
            and agrees not to cause the other Party to suffer damages.
          </p>
          <p>
            8.2 The indemnitee Party shall (i) provide notification
            to the indemnitor Party promptly in writing of any
            legal actions or claims, (ii) provide information to
            the indemnitor Party of the legal representative in
            the relevant legal action or claim, and (iii) provide
            appropriate and sufficient information and reasonable
            support to the indemnitor Party to defend against such
            legal actions or claims, or to reach arbitration resolution.
          </p>
          <p>
            8.3 The indemnitee Party agrees not to engage in settlement
            of any legal actions or claims without prior written consent
            of the indemnitor Party, and such consent shall not be
            improperly withheld or delayed.
          </p>

          <h3>Article 9 (No Warranty)</h3>
          <p>
            9.1 THE SERVICES IS PROVIDED AS-IS BASIS,
            AND EXCEPT AS EXPRESSLY STIPULATED IN THIS AGREEMENT, SSC,
            ITS SUBSIDIARIES AND AFFILIATE COMPANIES, CORPORATE OFFICERS,
            BOARD MEMBERS, REPRESENTATIVES, EMPLOYEES AND SUPPLIERS
            (COLLECTIVELY, THE “SSC REPRESENTATIVES”) DISCLAIM ALL WARRANTY,
            EXPRESS OR IMPLIED, OF ANY KIND, INCLUDING, BUT NOT LIMITED TO,
            ACCURACY, RECENCY, COMPLETENESS, UTILITY, FITNESS FOR PARTICULAR PURPOSES,
            SAFETY, LEGALITY AND TRUTHFULNESS, IN RELATION TO THE SERVICES.
          </p>
          <p>
            9.2 THE USER SHALL ACKNOWLEDGE AND AGREE THAT SSC SHALL
            NOT GUARANTEE PERPETUAL, STABLE, ERROR-FREE OR SAFE ACCESS
            TO THE WEBSITES AND THE SERVICES, AND THAT THE SERVICES MAY BE
            TEMPORARILY SUSPENDED DUE TO MAINTENANCE, UPGRADES, OR OTHER
            EXTERNAL CAUSES BEYOND SSC’S REASONABLE CONTROL.
          </p>

          <h3>Article 10 (Limitations of Liability)</h3>
          <p>
            10.1 SSC REPRESENTATIVES SHALL NOT, UNDER ANY CIRCUMSTANCES,
            BEAR RESPONSIBILITY FOR COSTS OF THE PURCHASE OF REPLACEMENT
            PRODUCTS OR SERVICES, THE LOSS OF PROFITS, INDIRECT OR PUNITIVE
            DAMAGES, OR LOSS OF CREDIBILITY ARISING IN CONNECTION WITH THE
            ACCESS TO OR USE OF THE SERVICES.
          </p>
          <p>
            10.2 SSC REPRESENTATIVES’ LIABILITY TO THE USER,
            WHETHER IN CONTRACT OR TORT, AND WHETHER RESULTING FROM
            WILLFUL INTENT OR NEGLIGENCE, SHALL BE LIMITED TO DIRECT
            AND ACTUAL DAMAGES.
          </p>
          <p>
            10.3 THE USER BEAR RESPONSIBILITY FOR DAMAGES
            INCURRED TO SSC, ARISING OUT OF ANY BREACH OF
            THIS AGREEMENT BY THE USER.
          </p>

          <h3>Article 11 (Information Disclosure)</h3>
          <p>
            11.1 The User acknowledges and agrees that SSC
            reserves the right to access, confirm, protect,
            and disclose any information in connection with
            the Services under any of the circumstances
            listed below:
          </p>
          <p>
            (1) In the event that such action is required by law,
            regulations or government request;
          </p>
          <p>
            (2) In the course of performance of this Agreement or
            investigation of potential violation of this Agreement
            by the User;
          </p>
          <p>
            (3) In the event that such action is necessary
            for the discovery of, prevention of, or response
            to impropriety, maintaining the safety of
            the Services or solving the technical problems;
          </p>
          <p>
            (4) In the event that such action is necessary
            for safety or to protect the rights of SSC or
            third parties.
          </p>
          <p>
            However, in cases in which the such information
            contains the User’s Confidential Information,
            the provisions of Article 12 shall take precedence
            over those of this Article.
          </p>

          <h3>Article 12 (Nondisclosure Obligation)</h3>
          <p>
            12.1 Each of the Parties shall maintain confidentiality,
            and neither of the Parties shall disclose Confidential
            Information to any third party for any purpose.
          </p>
          <p>However, this condition shall not apply to the following information:</p>
          <p>
            (1) Information for which publication or disclosure
            to a third party has been authorized by prior written
            consent of the Disclosing Party;
          </p>
          <p>
            (2) Information which was already in the possession
            of the Receiving party prior to delivery of such
            information;
          </p>
          <p>
            (3) Information which, after receipt by
            the Receiving Party from the Disclosing Party,
            becomes public knowledge other than as a result of
            disclosure by the Receiving Party;
          </p>
          <p>
            (4) Information disclosed to a third party
            by the Disclosing Party without imposition
            of a confidentiality obligation;
          </p>
          <p>
            (5) Information developed independently by
            the Receiving Party without the use of
            Confidential Information of the Disclosing Party,
            and without violation of this Agreement;
          </p>
          <p>
            (6) Information required to be disclosed by
            government request or by law, provided that
            the Receiving Party notifies the Disclosing
            Party of the relevant request in writing prior
            to disclosure, and discuss the method of disclosure
            with the Disclosing Party.
          </p>
          <p>
            12.2 All Confidential Information shall
            remain the exclusive property of the Disclosing Party.
            Unless otherwise agreed upon between the Disclosing
            Party and the Receiving Party, the User shall have
            no rights regarding to Confidential Information.
          </p>

          <h3>Article 13 (Restrictions)</h3>
          <p>13.1 In using the Services, the User shall not commit any of the following:</p>
          <p>
            Posting or transmitting, or causing to be posted or
            transmitted, any communication or solicitation designed
            or intended to obtain password, account, or private
            information from any user;
          </p>
          <p>
            (1) Violate the security of any computer network,
            crack passwords, transfer or store illegal material
            (including material that may be considered threatening or obscene),
            or engage in any kind of illegal activity;
          </p>
          <p>
            (2) Running any form of auto-responder,
            "spam" on the Services, or any processes
            that run or are activated while the User are
            not logged on to the Websites, or that otherwise
            interfere with the proper working of or place an unreasonable
            load on the Services' infrastructure.
          </p>
          <p>
            (3) Use of manual or automated software,
            devices, or other processes to "crawl"
            or "spider" the Websites; and
          </p>
          <p>
            (4) Decompile, reverse engineer, or otherwise attempt
            to obtain the source code of the Services.
          </p>

          <h3>Article 14 (Legal Compliance)</h3>
          <p>
            14.1 In using the Services, the User agrees to
            comply with all applicable laws and regulations.
          </p>
          <p>
            14.2 SSC reserves the right to investigate the use of
            the Services by the User for the purpose of determining
            the fact of breach of this Agreement, or strict compliance
            with applicable laws, regulations, legal procedure or
            government request, but shall not be obligated to do so.
          </p>

          <h3>Article 15 (Privacy Policy)</h3>
          <p>15.1 SSC shall treat personally identifiable information in accordance with SSC Privacy Policy as currently located at https://www.siliconstudio.co.jp/en/privacypolicy.html, which is deemed to be incorporated into this Agreement by reference. User’s acceptance of this Agreement constitutes User’s acceptance to be bound by SSC Privacy Policy. SSC may change or modify SSC Privacy Policy at any time without notice to the User.</p>

          <h3>Article 16 (Term)</h3>
          <p>
            16.1 Unless otherwise agreed upon between SSC and the User,
            this Agreement becomes upon consent by the User to this Agreement,
            and remains effective for a term of six months. If neither SSC nor
            the User notifies the other Party, two months prior to the expiry
            date, of intent to terminate this Agreement, it shall be renewed
            for a period of six months thereafter, and the same condition
            shall apply subsequently.
          </p>
          <p>
            16.2 The provisions of Article 5; Articles 8 through 12;
            Article 13, Paragraph1; Article 15; this Article,
            Paragraph 3, Paragraph 4; Article 17; Article 18;
            and Article 19 shall survive any termination or
            expiration of this Agreement for any reason whatsoever
            and remain in force.
          </p>

          <h3>Article 17 (Termination)</h3>
          <p>
            17.1 When any of the following circumstances arise,
            either of the Parties may terminate this Agreement
            without notification to the other Party:
          </p>
          <p>
            (1) In the event that one of the Parties violates the
            terms of this Agreement, and does not cure such breach
            within a reasonable period of time designated in a written notice;
          </p>
          <p>(2) In the event that a note or check is dishonored;</p>
          <p>
            (3) In the event of an application for bankruptcy,
            civil rehabilitation proceedings, corporate reorganization
            proceedings or special liquidation, or the initiation of such proceedings;
          </p>
          <p>
            (4) In the event of initiation of provisional seizure,
            provisional disposal, seizure, or attachment or auction
            proceedings for tax delinquency;
          </p>
          <p>
            (5) In the event of cessation or elimination of business,
            or a decision to transfer, alter, dissolve or merge business
            operations;
          </p>
          <p>
            (6) In the event of corporate acquisition of one
            of the Parties by a third party, or of a change in
            principal stockholders;
          </p>
          <p>
            (7) In the event of other marked deterioration
            in status of assets, or the ascertainment of
            reasonable grounds for anticipation of such deterioration.
          </p>

          <h3>Article 18 (Force Majeure)</h3>
          <p>
            18.1 Neither Party shall be responsible for failure
            or delay to perform any obligation under this Agreement
            where such failure or delay is due to or arises out of
            any cause beyond its reasonable control and without the
            fault or negligence of such Party, including,
            without limitation, terrorism, fire, civil disturbance,
            war, rebellion, earthquake, flood and similar occurrences,
            provided that performance of obligation shall resume immediately
            after such cause no longer prevents or delays such performance.
          </p>

          <h3>Article 19 (Miscellaneous)</h3>
          <p>
            19.1 The failure of either Party to exercise,
            in any respect, any right provided hereunder shall
            not be deemed a waiver of any further rights hereunder.
          </p>
          <p>
            19.2 If any provision of this Agreement is found to be
            unenforceable or invalid, the relevant terms may be
            deleted and replaced with new terms that most effectively
            reflect the original intent, and this shall have no influence
            whatsoever on the validity of the other terms of this Agreement.
          </p>
          <p>
            19.3 The User shall not assign, transfer or sublicense
            any of its rights or obligations hereunder without
            prior written consent of SSC. SSC may transfer,
            assign or delegate any of its rights or obligations
            hereunder without obtaining User’s consent.
          </p>
          <p>
            19.4 This Agreement shall be governed by and construed in
            accordance with the laws of Japan.
          </p>
          <p>
            19.5 The Parties agree that the Tokyo District Court
            shall have exclusive jurisdiction by consent as the court
            of first instance.
          </p>
          <p>
            19.6 Both Parties agree that this Agreement is the
            complete and exclusive statement of the mutual understanding
            of the Parties and supersedes and cancels all previous written
            or oral agreements relating to the subject matter of this Agreement.
          </p>
          <p>
            19.7 No agency, partnership, joint venture, or employment
            is created as a result of this Agreement and the User does
            not have any authority of any kind to bind SSC legally in
            any respect whatsoever.
          </p>
          <p>- End -</p>
        </div>
        <div className="sticky bottom-0 bg-white w-full border-t bg-gray-100">
          <button
            className="p-2 m-auto w-full"
            onClick={ onCloseClick }>
            Close
          </button>
        </div>
      </div>
    </div>
  )
}
