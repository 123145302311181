import { Action, ActionType, State } from '../interfaces'

export default (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_DAU:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          dau: action.payload,
        },
      }

    case ActionType.SET_DAILY_PURCHASES:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          dailyPurchases: action.payload,
        },
      }

    case ActionType.SET_DAILY_NEW_USERS:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          dailyNewUsers: action.payload,
        },
      }

    case ActionType.SET_MAU:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          mau: action.payload,
        },
      }

    case ActionType.CLOSE_SIDEBAR:
      return {
        ...state,
        showSidebar: false,
      }

    case ActionType.TOGGLE_SIDEBAR:
      return {
        ...state,
        showSidebar: !state.showSidebar,
      }

    default:
      return state
  }
}
