import React, { useState } from 'react'

import './App.css'

import { Amplify } from 'aws-amplify'
import { observer } from 'mobx-react-lite'
import { Auth } from './backend/Auth'
import Authentication from './pages/authentication/Authentication'
import awsconfig from './aws-exports'
import Store, { StoreContext } from './store'
import { startRouter } from './router'
import Loading from './components/loading/Loading'
import Dashboard from './pages/dashboard/Dashboard'
import ProjectData from './pages/project-data/ProjectData'
import Sidebar from './components/sidebar/Sidebar'
import GlobalProvider from './context/GlobalState'
import Guest from './components/guest/Guest'
import { Path } from './interfaces/view'

Amplify.configure(awsconfig)

const StoreProvider: React.FunctionComponent = ({ children }) => {
  const store = new Store(Auth)

  return (
    <StoreContext.Provider value={ store }>
      { children }
    </StoreContext.Provider>
  )
}

const Main = observer(() => {
  const store = React.useContext(StoreContext)
  const { authStore, viewStore } = store
  const { currentPath } = viewStore

  const content = () => {
    if (currentPath === Path.PROJECT_DATA) {
      return <ProjectData />
    }

    return <Dashboard />
  }

  return (
    <div>
      <div
        className={ `
          ${viewStore.isSidebarVisible ? 'w-52' : 'hidden'}
          fixed bg-gray-100
          border-r-2 border-gray-800 z-50
        ` }>
        <Sidebar signOut={ () => authStore.signOut() } />
      </div>
      <div className={ `
        ${viewStore.isSidebarVisible ? 'ml-52' : ''}
      ` }>
        { content() }
      </div>
    </div>
  )
})

const App = observer(() => {
  const store = React.useContext(StoreContext)
  const { authStore, viewStore } = store

  const [routerStarted, setRouterStarted] = useState(false)

  if (!routerStarted) {
    startRouter(store)
    setRouterStarted(true)
  }

  if (
    viewStore.currentPath === Path.CONTACT
    || viewStore.currentPath === Path.HIRING
    || viewStore.currentPath === Path.HOME
    || viewStore.currentPath === Path.PRODUCT
    || viewStore.currentPath === Path.TEAM
  ) {
    return (
      <GlobalProvider>
        <Guest />
      </GlobalProvider>
    )
  }

  if (authStore.isFetchingUser) {
    return (
      <div className="absolute top-1/2 w-full mx-auto">
        <Loading />
      </div>
    )
  }

  if (authStore.user && authStore.user.email_verified) {
    return (
      <GlobalProvider>
        <div>
          <Main />
        </div>
      </GlobalProvider>
    )
  }

  return <Authentication />
})

export default () => (
  <StoreProvider>
    <App />
  </StoreProvider>
)
