import React from 'react'
import { observer } from 'mobx-react-lite'
import { StoreContext } from '../../store'
import ResetPassword from '../../components/authentication/ResetPassword'
import SignIn from '../../components/authentication/SignIn'
import SignUp from '../../components/authentication/SignUp'
import { Path } from '../../interfaces/view'
import ConfirmSignUp from '../../components/authentication/ConfirmSignUp'
import ConfirmResetPassword from '../../components/authentication/ConfirmResetPassword'

interface TabProps {
  text: string
  handleClick: () => void
  selected: boolean
}

const Tab = ({ text, handleClick, selected }: TabProps) => {
  if (selected) {
    return (
      <button
        className={ `
          inline-block w-1/2 p-2
          text-center text-emerald-600
          font-medium
          border-t-4 border-l border-r
          border-t-emerald-600
        ` }
        onClick={ handleClick }>
        { text }
      </button>
    )
  }

  return (
    <button
      className={ `
        inline-block w-1/2 p-2
        text-center text-gray-600
        border-b
      ` }
      onClick={ handleClick }>
      { text }
    </button>
  )
}

const AuthTab = observer(() => {
  const {
    authStore,
    viewStore,
  } = React.useContext(StoreContext)

  const content = viewStore.currentPath === Path.SIGN_IN
    ? <SignIn />
    : <SignUp />

  const isSelected = (path: Path) => path === viewStore.currentPath

  return (
    <div className="mt-16 max-w-md mx-auto">
      <div>
        <Tab
          text="Sign In"
          handleClick={ () => authStore.showSignIn() }
          selected={ isSelected(Path.SIGN_IN) } />
        <Tab
          text="Create Account"
          handleClick={ () => authStore.showSignUp() }
          selected={ isSelected(Path.SIGN_UP) } />
      </div>
      { content }
    </div>
  )
})

export default observer(() => {
  const { viewStore } = React.useContext(StoreContext)
  const { currentView } = viewStore

  switch (currentView.path) {
    case Path.CONFIRM_SIGN_UP:
      return (
        <ConfirmSignUp
          email={ currentView.state.email } />
      )
    case Path.SIGN_IN:
      return <AuthTab />
    case Path.SIGN_UP:
      return <AuthTab />
    case Path.RESET_PASSWORD:
      return <ResetPassword />
    case Path.CONFIRM_RESET_PASSWORD:
      return (
        <ConfirmResetPassword
          email={ currentView.state.email } />
      )
    default:
      return <></>
  }
})
