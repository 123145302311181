import React, {
  useContext,
  useEffect,
  useState,
} from 'react'

import { observer } from 'mobx-react-lite'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { StoreContext } from '../../store'
import { GraphFileName } from '../../interfaces'

import {
  AbnormalPlayersCard,
  GraphCard,
  GraphCardWithDates,
  RetentionCard,
} from '../../components/graph-card/GraphCard'

import {
  dailyPurchasesSpec,
  dauSpec,
  LTVSpec,
  mauSpec,
  survivalSpec,
} from '../../components/graph-card/Specs'

import {
  validateDAU,
  validateLTV,
  validateMAU,
  validatePurchases,
  validateSurvival,
} from '../../components/upload/utils'

import ErrorToast from '../../components/error-toast/ErrorToast'

interface SummaryProps {
  title: string
  children: React.ReactNode
}

export default observer(() => {
  const { dashboardStore, viewStore } = useContext(StoreContext)

  const {
    abnormalPlayersData,
    dailyPurchasesData,
    dauData,
    ltvData,
    mauData,
    retentionData,
    survivalData,
  } = dashboardStore

  const [error, setError] = useState('')

  useEffect(() => {
    dashboardStore.loadData()
  }, [])

  const Summary = ({ title, children }: SummaryProps) => {
    return (
      <div className={ `
        flex-1 p-3 w-max mr-4 bg-gray-800
        border-t-4 border-emerald-400
      ` }>
        <h5 className="text-white text-xs leading-4 font-bold">
          { title }
        </h5>
        { children }
      </div>
    )
  }

  return (
    <div className="dashboard bg-gray-900 text-white">
      { /* Header */ }
      <h1 className="p-4 px-6 text-xl leading-7 font-semibold">
        { !viewStore.isSidebarVisible
          && (
          <FontAwesomeIcon
            icon={ faBars }
            color="white"
            className="cursor-pointer mr-4"
            onClick={ () => viewStore.showSidebar() } />
          ) }
        Dashboard
      </h1>

      { /* Summary */ }
      <div className="p-6 border-t-2 border-b-2 border-gray-800">
        <h3 className="mb-4 text-white text-sm leading-5 font-semibold uppercase">
          Summary
        </h3>
        <div className="flex place-content-around overflow-scroll">
          <Summary title="Monthly Active Users">
            <p>{ dashboardStore.mau.toLocaleString() }</p>
          </Summary>

          <Summary title="Active Users Today">
            <p>{ dashboardStore.dau.toLocaleString() }</p>
          </Summary>

          <Summary title="Best Week">
            <p> TODO </p>
          </Summary>

          <Summary title="Best Month">
            <p>
              <span> { dashboardStore.bestMonth.month } </span>
              <span>({ dashboardStore.bestMonth.value.toLocaleString() })</span>
            </p>
          </Summary>

          <Summary title="Best Day">
            <p>
              <span>{ dashboardStore.bestDay.day } </span>
              <span>({ dashboardStore.bestDay.value.toLocaleString() })</span>
            </p>
          </Summary>

          <Summary title="Best Day of the Week">
            <p>
              <span>{ dashboardStore.bestDayOfWeek.day }</span>
              <span>({ dashboardStore.bestDayOfWeek.avg.toLocaleString() } on avg)</span>
            </p>
          </Summary>
        </div>
      </div>

      { /* Error Toast */ }
      { error
          && (
          <ErrorToast
            error={ error }
            onDismiss={ () => setError('') } />
          ) }

      { /* Graphs */ }
      <div className="p-6 flex flex-wrap">
        { /* Daily Active Users */ }
        <GraphCardWithDates
          data={ dauData }
          fileName={ GraphFileName.DAU }
          onUploadDone={ () => dashboardStore.fetchDau() }
          setError={ (err) => setError(err) }
          spec={ dauSpec(dauData || '') }
          title="Daily Active Users"
          validateFileContent={ validateDAU } />

        { /* Monthly Active Users */ }
        <GraphCard
          data={ mauData }
          fileName={ GraphFileName.MAU }
          onUploadDone={ () => dashboardStore.fetchMau() }
          setError={ (err) => setError(err) }
          spec={ mauSpec(mauData || '') }
          title="Monthly Active Users"
          validateFileContent={ validateMAU } />

        { /* Daily Purchases */ }
        <GraphCardWithDates
          data={ dailyPurchasesData }
          fileName={ GraphFileName.PURCHASES }
          onUploadDone={ () => dashboardStore.fetchPurchases() }
          setError={ (err) => setError(err) }
          spec={ dailyPurchasesSpec(dailyPurchasesData || '') }
          title="Daily Purchases"
          validateFileContent={ validatePurchases } />

        { /* Survival (10 days) */ }
        <GraphCard
          data={ survivalData }
          fileName={ GraphFileName.SURVIVAL_10_DAYS }
          onUploadDone={ () => dashboardStore.fetchSurvival() }
          setError={ (err) => setError(err) }
          spec={ survivalSpec(survivalData || '') }
          title="Survival - 10 days (all players)"
          validateFileContent={ validateSurvival } />

        { /* LTV */ }
        <GraphCard
          data={ ltvData }
          fileName={ GraphFileName.LTV }
          onUploadDone={ () => dashboardStore.fetchLtv() }
          setError={ (err) => setError(err) }
          spec={ LTVSpec(ltvData || '') }
          title="LTV"
          validateFileContent={ validateLTV } />

        <AbnormalPlayersCard
          data={ abnormalPlayersData }
          url={ dashboardStore.abnormalPlayersUrl }
          onUploadDone={ () => dashboardStore.fetchAbnormalPlayers() }
          setError={ (err) => setError(err) } />

        { /* Retention */ }
        <RetentionCard
          data={ retentionData }
          onUploadDone={ () => dashboardStore.fetchRetention() }
          setError={ (err) => setError(err) } />
      </div>
    </div>
  )
})
