import React from 'react'

import { observer } from 'mobx-react-lite'

interface Props {
  error: string
  onDismiss: () => void
}

export default observer(({
  error,
  onDismiss,
}: Props) => {
  return (
    <div className={ `
      sticky top-0 z-50
      border-b-2 border-gray-800
    ` }>
      <div className={ `
        flex flex-row place-content-between items-baseline
        p-2 px-4 bg-red-200 text-red-900
      ` }>
        <p className="m-0">
          { error }
        </p>
        <button
          title="dismiss"
          className="m-0 cursor-pointer text-right"
          onClick={ () => onDismiss() }>
          x
        </button>
      </div>
    </div>
  )
})
