import './team.scss'
import arnaud from '../../assets/team/arnaud.png'
import bogdan from '../../assets/team/bogdan.png'
import cristian from '../../assets/team/cristian.png'
import pooja from '../../assets/team/pooja.png'
import seb from '../../assets/team/seb.png'
import Footer from '../../components/footer/Footer'
import TopHeader from '../../components/top-header/TopHeader'

export default function Team() {
  return (
    <div className="team">
      <TopHeader />

      <div id="team">
        <div className="row">
          { /* <!-- Cristian --> */ }
          <div className="member">
            <img src={ cristian } alt="" />
            <h4 className="title team-title">
              Cristian Conteduca, MSc
            </h4>
            <h4 className="role">
              General Manager
            </h4>
            <p className="info">
              Cristian is a data engineer specialized in developing,
              testing and maintaining database architectures and
              YOKOZUNA data's large-scale data processing systems.
              He holds an MSc in Atmospheric Physics from the
              University of Turin (Italy) and has extensive experience
              in scientific programming, physical modeling,
              quantitative analysis and software engineering.
            </p>
          </div>

          { /* <!-- Pooja --> */ }
          <div className="member">
            <img src={ pooja } alt="" />
            <h4 className="title team-title">
              Pooja Revanna, MSc
            </h4>
            <h4 className="role">
              Data Engineer
            </h4>
            <p className="info">
              Pooja is a data engineer with strong expertise in Java,
              SQL and Cassandra databases. She holds a Master’s degree
              in Computer Applications (MCA) from the Visvesvaraya
              Technological University at Belgaum (India) and
              has 5 years of experience working as a Java developer
              and data engineer. She builds processes supporting data transformation,
              data streaming, data migration and highly scalable big data
              storage management.
            </p>
          </div>
        </div>

        <div className="row">
          { /* <!-- Arnaud --> */ }
          <div className="member">
            <img src={ arnaud } alt="" />
            <h4 className="title team-title">
              Arnaud Chenyensu, MSc
            </h4>
            <h4 className="role">
              Full-Stack Engineer
            </h4>
            <p className="info">
              Arnaud is a frontend and backend engineer with 4+ years
              of experience. He holds a master's degree in Information
              Technology from the University of Réunion Island's
              Engineering School (ESIROI).
            </p>
          </div>
          { /* <!-- Sebasitan --> */ }
          <div className="member">
            <img src={ seb } alt="" />
            <h4 className="title team-title">
              Sebastian Reca, MSc
            </h4>
            <h4 className="role">
              Full-Stack Engineer
            </h4>
            <p className="info">
              Sebastian is a full stack developer and has a huge experience
              in developing web applications with 8 years’ experience.

              Having contributed to different kinds of industries with great
              JavaScript, PHP, Python, java skills, as well as
              implementation of various technologies and frameworks.
            </p>
          </div>
        </div>
        <div className="row">

          { /* <!-- Bogdan --> */ }
          <div className="member">
            <img src={ bogdan } alt="" />
            <h4 className="title team-title">
              Bogdan Bogdanovic, MSc
            </h4>
            <h4 className="role">
              Full-Stack Engineer
            </h4>
            <p className="info">
              Bogdan is a Full Stack Developer who has been developing web and mobile applications
              for almost 8 years, across virtually every industry.
              He holds a master's degree from Singidunum University.
              He is specialized in JavaScript and JS frameworks like react, Vue.js,
              Angular, PHP and Laravel. Bogdan built and published many web sites and
              apps for the fin-tech, booking, e-commerce, payment integrations and
              business automation industries.
            </p>
          </div>
          <div className="member" />
        </div>
      </div>

      <Footer />
    </div>
  )
}
