export const csvToObjects = (content: string) => {
  const [headers, ...rows] = content.split('\n')

  const resRows: any = []

  rows.forEach((rowString) => {
    // we make sure the lines are not empty
    if (rowString.trim() !== '') {
      const row = rowString.split(',')
      const res: any = {}
      headers.split(',').forEach((header, i) => {
        res[header.trim()] = row[i]
      })
      resRows.push(res)
    }
  })

  return resRows
}

export const filterByMonth = (
  startDate: Date,
  data: string,
): string => {
  const [headers, ...rows] = data.trim().split('\n')

  const result = rows.filter((row) => {
    const [d, _] = row.split(',')
    const date = new Date(d)

    if (
      date.getMonth() === startDate.getMonth()
      && date.getFullYear() === startDate.getFullYear()
    ) {
      return true
    }

    return false
  })

  return [headers, ...result].join('\n')
}

// returns the data between two dates
// from a csv string
// (assuming that the date is the first column)
export const filterByDates = (
  startDate: Date,
  endDate: Date,
  data: string,
): string => {
  const [headers, ...rows] = data.trim().split('\n')

  const result = rows.filter((row) => {
    const [date, _] = row.split(',')
    const timestamp = new Date(date).valueOf()

    const startTimestamp = startDate.valueOf()
    const endTimestamp = endDate.valueOf()

    if (
      timestamp > startTimestamp
      && timestamp < endTimestamp
    ) {
      return true
    }

    return false
  })

  return [headers, ...result].join('\n')
}

// returns the lowest and highest dates
// from a csv string
// (assuming that the data is ordered)
export const getDateRange = (data: string) => {
  const [_headers, ...rows] = data.trim().split('\n')
  const firstRow = rows[0]
  const lastRow = rows[rows.length - 1]

  return [
    new Date(firstRow.split(',')[0]),
    new Date(lastRow.split(',')[0]),
  ]
}
