import React from 'react'

interface Props {
  msg: string
}

export default ({ msg }: Props) => {
  return (
    <div className="bg-red-300 text-red-900 p-2">
      { msg }
    </div>
  )
}
