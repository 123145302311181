import './hiring.scss'
import Footer from '../../components/footer/Footer'
import TopHeader from '../../components/top-header/TopHeader'

export default function Hiring() {
  return (
    <div>
      <TopHeader />

      { /* <!-- How does it work --> */ }
      <div className="how-section section main-content">
        <div className="container">
          <h3 className="title">
            BACKEND ENGINEER
          </h3>
          <div>
            <div className="position">
              <p>Backend engineer, at Yokozuna data (remote position)</p>
              <p>Keywords studios is an international minded game and technology company.</p>
              <p>Our offices are located in Tokyo and Amsterdam.</p>
              <p>
                We are looking for a backend engineer to develop
                a game predictive analytic technologies,
                based on our cutting-edge data science research.
              </p>
            </div>
            <div className="requirements">
              <h4>Requirements:</h4>
              <ul>
                <li>Back-end programming (Java, Node.js, Python ...)</li>
                <li>Scripting (Bash, Perl, ...)</li>
                <li>Experience with both SQL and NoSQL databases</li>
                <li>Cloud Experience (AWS)</li>
                <li>Problem solver (a must!)</li>
              </ul>
              <h4>Appreciated but not required:</h4>
              <ul>
                <li>Big data technologies (Storm, Pig, MapReduce model, ...)</li>
                <li>R, Machine learning</li>
              </ul>
              <h4>info@yokozunadata.com</h4>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}
